.paymentPage {
  .contactInfo {
    min-height: 200px !important;
  }

  .ggccText {
    display: block;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 14px !important;
    color: #231f20;
  }

  .StripeElement {
    padding: 8px;
    border: 1px solid #e6e7e8;
    width: unset;
    border-radius: 3px;
  }

  .cardNoDiv {
    margin-bottom: 20px;
  }

  .errMessage {
    color: #ff6969;
    font-size: 14px;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
  }

  .policyText {
    margin: 20px 0;
    color: #000000;
    font-size: 14.5px;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
  }

  .terms {
    color: #00aeef;
    cursor: pointer;
  }

  .fieldTitle {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    color: #231f20 !important;
    font-size: 14px !important;
  }

  .payBtn {
    text-align: right;
    .MuiCircularProgress-root {
      width: 24px !important;
      height: 24px !important;
      color: #ffffff !important;
    }
  }

  .btnContinue {
    margin: 10px 0 0 0 !important;
  }

  .payCounter {
    background: #eceded;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
    p {
      margin: 0;
      color: #898787;
      font-family: "Roboto", Myanmar3;
      font-weight: 400;
    }
    span {
      font-weight: bold;
      color: #898787;
    }
  }
  .paymentSelect {
    padding-right: 10px !important;
  }

  .offlinePayment {
    text-align: center;
    img {
      width: 210px;
      margin: 10px 0px;
    }
    .mbank {
      width: 210px;
    }
    .cod {
      width: 100px;
    }
    .ccpp123 {
      width: 220px;
    }
    .mpu {
      width: 175px;
    }
    .kbzquickpay {
      width: 210px;
    }
    .paypal {
      width: 195px;
    }
    .kbzpay,
    .ggotc,
    .cocostore {
      width: 100px;
    }
    .infoText {
      display: block;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      font-size: 14px !important;
      color: #231f20;
    }
    .btnContinue {
      margin: 20px 0px !important;
    }
  }

  .wavemoney {
    img {
      width: 190px;
    }
    .MuiInputBase-formControl {
      padding-right: 0px !important;
    }
    button {
      margin: 20px 0px !important;
    }
  }

  .waveimg {
    display: block;
    text-align: center;
    margin: 10px 0px;
  }
}
.termsAndConditionsModal {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  font-family: Roboto, Myanmar3 !important;
  font-weight: 400;
  font-size: 16px;
  color: #231f20;
  line-height: 25px;
  p {
    text-align: center;
  }
  .close-icon {
    float: right;
    margin-top: -20px;
  }
}
@media (max-width: 700px) {
  .paymentPage {
    .title {
      margin-left: 10px;
    }
    .flightDetailBox {
      margin-top: 15px;
    }
  }
  .progressbar li {
    min-width: 90px !important;
    position: unset;
  }
  .step-info {
    font-size: 12px !important;
  }
}
.cardHolderDiv .MuiInputBase-formControl {
  padding-right: 0 !important;
}
@media (min-width: 1025px) {
  .paymentPage .contactMobile {
    padding-top: 0px !important;
    margin-top: 0 !important;
  }
  .paymentPage .flightDetailBox {
    margin-top: 15px !important;
  }
  .paymentPage {
    padding: 15px;
  }
  .paymentPage .btnContinue {
    width: 100%;
    margin: 0 !important;
    height: 46px;
  }
  form.checkout > .MuiGrid-root:last-child {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 1024px) {
  .paymentPage {
    padding: 15px;
  }
  .paymentPage .btnContinue {
    width: 100%;
    margin: 0 !important;
    height: 46px;
  }
  form.checkout > .MuiGrid-root:last-child {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 780px) {
  .busPayButton {
    margin-top: 20px !important;
  }
  .paymentPage .contactInfo {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  .busPayNowButton {
    margin-top: 20px !important;
  }
}
