@media (max-width: 780px) {
  .web-from-to {
    display: none !important;
  }

  .dateRange {
    display: none;
  }
  body {
    background-color: #f4f3f4;
  }
  .loadingWrapper {
    width: 110% !important;
  }
  .bgHome {
    //background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7EXUP4jleCD1DdQ2yw4mAo/6f50b7c340784a9a2ae578903ee4b4b6/background-mobile-1080.jpg") !important;
    // background-image: url("../images/Bagan2.jpg");
    background-color: #273142 !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto !important;
    background-size: 100% 55% !important;
    background-position: top !important;
    // background-color: #000000 !important;
  }
  .clickAwayDiv {
    float: none;
    display: inline;
  }
  .homeContainer {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    // padding: 0px 20px !important;
    padding: 0 !important;
    height: 100vh;
    .DateInput_input {
      font-size: 18px !important;
      text-align: right;
      float: right;
    }
    .DateInput_input__focused {
      width: 150px;
      margin-left: 150px;
      margin-top: -40px !important;
      position: absolute;
    }
  }
  .mobile-from-to {
    margin-top: 20px;
    display: block !important;
    label {
      float: left;
      width: 19%;
      font-size: 18px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
    .MuiInputBase-input {
      width: 100%;
      float: right;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 700;
      color: #231f20;
      font-size: 18px;
    }
    .MuiFormControl-root {
      width: 80%;
    }
  }
  .bgHome {
    height: 100vh !important;
    min-height: 100vh !important;
  }
  #homeFlag .flag-select {
    margin-top: 14px !important;
  }
  .myanmar {
    font-size: 53px !important;
    line-height: 30px !important;
  }
  .bgHome .logo {
    width: 150px !important;
    padding-top: 9px !important;
  }
  .menu {
    min-height: 70px !important;
    padding-left: 20px !important;
    span {
      font-size: 20px !important;
      float: right;
      margin-left: 20px;
    }
  }
  .homeCenterInfo {
    font-family: "Poppins", Myanmar3 !important;
    font-weight: 500;
    font-size: 15px !important;
    height: 15px !important;
    margin-top: calc(10vh - 120px) !important;
    .divDiscover {
      width: 268px !important;
    }
  }
  .search.inAppSearch {
    height: 300px !important;
  }
  .search {
    // height: 290px;
    height: 240px !important;
    padding: 0px !important;
    margin-top: -45px !important;
    .swapDestination {
      border: 1px solid #e6e7e8;
      background: #fff;
      border-radius: 50px;
      padding: 6px;
      margin: 0px;
      left: 20px;
      z-index: 1;
      position: absolute;
      top: -30px;
    }
  }
  .hasReturn,
  .inAppSearch {
    .swapDestination {
      top: -18px !important;
    }
  }
  .menuList {
    display: none;
  }
  .container {
    max-width: 100% !important;
  }

  .myanmarCitizen {
    width: 100%;
    position: relative !important;
    margin-top: -90px;
    height: 45px;
    padding: 0px !important;
    margin-left: 0px !important;
    .MuiFormControlLabel-root {
      margin: auto;
      padding-right: 5px;
    }
    label {
      span {
        width: 25% !important;
        margin: 0px;
      }
    }
    .MuiGrid-root {
      padding: 0px 20px;
    }
    .MuiFormGroup-row {
      height: 45px;
    }
    span {
      font-size: 16px !important;
      width: 45%;
    }
  }
  .myanmarCitizen.inApp,
  .busSearhRadio .myanmarCitizen {
    margin-top: -60px !important;
  }

  .returnFlightRadio {
    width: 100%;
    position: relative !important;
    margin-top: -45px;
    height: 45px;
    padding: 0px !important;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: 0px !important;
    .MuiRadio-colorSecondary .MuiSvgIcon-root {
      font-size: 1rem !important;
      color: #fff !important;
    }
    .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root {
      font-size: 1rem !important;
      color: #01b7f2 !important;
    }
    .MuiFormControlLabel-root {
      margin: auto;
      padding-right: 5px;
      margin-right: 5px !important;
    }
    label {
      span {
        width: 25% !important;
        margin: 0px;
      }
    }
    .MuiGrid-root {
      padding: 0px 20px;
    }
    .MuiFormGroup-row {
      height: 45px;
    }
    span {
      font-size: 16px !important;
      width: 45%;
      color: #fff;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      margin: auto;
    }
  }

  .searchIcon {
    display: none !important;
  }
  .btnBlueSearch {
    display: block !important;
    margin-top: -15.7vh !important;
    button {
      width: 100%;
      background-color: #01b7f2 !important;
      margin-top: -20px !important;
      text-transform: capitalize !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 500;
      font-size: 17px !important;
      height: 50px;
    }
    .btnDisable {
      background-color: #01b7f2 !important;
    }
  }
  .btnSearch {
    display: none !important;
  }
  #fl-home-btn-search span,
  #ia-fl-home-btn-search span {
    pointer-events: none;
  }
  .menu {
    float: none !important;
    text-transform: uppercase;
  }
  .showMenu {
    width: 300px;
    .MuiDrawer-paper {
      background-color: #000;
      opacity: 0.6;
      width: 290px;
      height: auto;
      top: 80px !important;
    }
  }
  .MuiBackdrop-root {
    top: 50px !important;
  }
  .homeMenu {
    float: left;
    top: 15px !important;
    padding: 0px !important;
    min-width: 12px !important;
    float: left;
    margin-top: 10px;
    opacity: 1 !important;
  }
  .checkReturnDate,
  .calendarIcon {
    opacity: 0 !important;
  }
  .menuIcon {
    float: left;
    min-width: 30px !important;
    // margin-top: 17px !important;
    width: 48px;
    height: 48px;
    margin-left: -12px !important;
    margin-top: 5px !important;
    // z-index: 1500;
    .MuiButton-root {
      min-width: 30px !important;
    }
    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }
  .chooseReturnDate {
    opacity: 1 !important;
    margin-left: 0px !important;
  }
  .departfrom {
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0px !important;
    padding: 0px 20px !important;
    min-width: 100% !important;
    position: relative;
  }
  .departfrom {
    .MuiInputBase-input {
      text-align: right;
    }
  }

  .MuiFormControl-root label {
    margin-top: -25px;
  }
  .MuiInputLabel-shrink {
    transform: none !important;
    line-height: 70px !important;
  }
  .datepicker {
    padding: 0px 20px !important;
  }
  .bgHome .DateInput_input__readOnly.DateInput_input__focused_3 {
    display: none;
  }
  .returnFlight {
    margin-top: 30px !important;
    float: none !important;
    margin-left: 0px !important;
  }
  .departFlight {
    margin-top: -8px !important;
  }
  .airportList {
    padding: 10px 15px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: #00aeef;
    line-height: 20px;
  }
  .departfrom {
    .MuiTextField-root {
      width: 100%;
    }
    .MuiInputBase-root {
      margin-top: 0px !important;
    }
  }
  .divpackages {
    margin-top: -30px;
  }
  .popupSearch {
    height: 95px;
    background-color: #00aeef;
    position: sticky;
    top: 0;
    .searchTitle {
      color: #ffffff;
      font-family: "Roboto", Myanmar3;
      font-weight: 500;
      line-height: 40px;
      padding-top: 2px;
      margin-left: 40%;
      font-size: 16px;
    }
    .MuiTextField-root {
      width: 93%;
      margin-top: -5px !important;
    }
    .MuiInputBase-input {
      text-align: left !important;
      padding: 10px 15px !important;
      border-radius: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    .MuiInputBase-input::placeholder {
      font-family: "Roboto", Myanmar3;
      font-weight: 400;
      font-size: 14px;
    }
    .MuiInputBase-root {
      left: 13px !important;
      margin-top: 10px !important;
    }
    .MuiButton-text {
      margin-left: -6px;
    }
    input {
      float: left;
      background-color: #fff;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: -30px;
    }
  }
  .topCity {
    height: 50px;
    color: #000000;
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 15px !important;
    line-height: 30px;
  }
  .passenger {
    padding-top: 15px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    border-radius: 5px;
    .MuiInputAdornment-root {
      display: none;
    }
    label {
      float: left;
      width: 15%;
      font-size: 18px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
  }

  .slideContainter {
    position: fixed;
    overflow-x: hidden;
    top: 0px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 1501;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-radius: 0px !important;
  }
  .closeAiport {
    float: left;
    margin-top: 5px !important;
    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }
  .airportSearchIcon {
    color: #2699fb !important;
  }
  .headerLogo {
    display: none !important;
  }

  .mdatepicker .SingleDatePicker {
    width: 100% !important;
    text-align: left;
  }
  .fieldLbl {
    float: left;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .mdatepickerReturn .SingleDatePicker {
    text-align: left;
    margin-left: 3px;
    width: 80%;
    .DateInput_input {
      margin-left: 22px;
    }
  }
  .SingleDatePickerInput_calendarIcon {
    margin: 0px !important;
    padding: 0px !important;
  }
  .mdatepickerReturn {
    margin-left: 3px !important;
  }
  .mdatepickerReturn .DateInput {
    width: 72% !important;
  }
  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 26%;
  }
  .mdatepicker .SingleDatePickerInput_calendarIcon {
    width: 47%;
  }

  .mdatepicker .SingleDatePickerInput__withBorder {
    width: 100% !important;
  }
  .mdatepicker label,
  .mdatepickerReturn label {
    width: 13% !important;
  }
  .mdatepicker {
    .DateInput {
      width: 52% !important;
      line-height: 50px !important;
      float: none !important;
    }
  }
  .mdatepickerReturn {
    padding-right: 3px !important;
    .DateInput {
      width: 75%;
      line-height: 50px !important;
      float: none !important;
    }
  }
  .mdatepicker,
  .mdatepickerReturn {
    height: 59px;
    margin: 0px !important;
    border-bottom: 1px solid #ddd;
    padding: 0px !important;

    .DateInput_input__focused {
      width: 0% !important;
    }
    .SingleDatePickerInput__withBorder {
      border: 0px !important;
      line-height: 29px;
      max-height: 59px;
    }

    .DateInput_input {
      padding: 5px 0px !important;
    }
    label {
      float: left;
      height: 60px;
      width: 25%;
      font-size: 16px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
    .DateInput__disabled,
    .SingleDatePickerInput__disabled {
      background: #fff !important;
    }
    .DateInput__disabled {
      width: 70% !important;
    }
  }
  .editForm {
    width: 100%;
  }
  .divSearch .MuiInputBase-input {
    width: 100% !important;
  }
  .searchBox {
    .MuiInputBase-input {
      width: 100% !important;
    }
  }

  //FOOTER
  .car-ads-box {
    flex-wrap: wrap;
  }
  .airport-transfer-ads,
  .multi-city-ads,
  .city-tour-ads,
  .city-to-city-ads {
    border: none;
  }
  .airport-transfer-ads {
    min-width: 168px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .divAirlinePartner {
    .bgWhite {
      flex-wrap: wrap;
      padding: 0 5px;
    }
  }
  .featuredInContainer {
    padding: 0 10px;
  }
  .divFeaturedIn {
    .col {
      width: 25%;
      padding: unset;
      .feature-img {
        width: 100% !important;
      }
    }
  }
  .grid-footer:last-child .titleSlider {
    text-align: left;
  }
  .bgHomeService .homeContainer {
    .hire:first-child {
      img {
        width: 80%;
      }
    }
  }
}
@media (max-width: 600px) {
  .divAirlinePartner {
    .bgWhite {
      div {
        width: unset;
      }
    }
  }
}
@media (max-width: 599px) {
  .grid-footer {
    margin-bottom: 35px !important;
  }
  .grid-footer:nth-child(3) {
    .footer-bottom {
      margin: 0 auto;
    }
    .titleSlider {
      text-align: center;
    }
  }
  .grid-footer:first-child,
  .grid-footer:nth-child(2) {
    display: flex;
    justify-content: center;
    height: auto;
  }
  .grid-footer:nth-child(4) {
    text-align: center;
    div > div {
      justify-content: center;
      div:last-child span {
        text-align: left;
      }
    }
  }
  .grid-footer:last-child {
    justify-content: center;
    .titleSlider {
      text-align: center;
    }
  }
  .sortingBox .MuiInput-root.mm-pull-right {
    width: 170px !important;
  }
}
@media (min-width: 400px) {
  .mdatepickerReturn label {
    width: 13% !important;
  }
  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 62px !important;
  }
  .mdatepickerReturn .SingleDatePicker .DateInput_input {
    width: 73% !important;
  }
  .mdatepickerReturn .DateInput {
    width: 75% !important;
  }
  .MuiContainer-root {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media (max-width: 420px) {
  .MuiContainer-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  //FOOTER
  .car-ads-box > div {
    height: unset !important;
    margin-bottom: 20px !important;
  }
  .airport-transfer-ads,
  .multi-city-ads,
  .city-tour-ads,
  .city-to-city-ads {
    height: unset;
  }
}
@media (max-width: 370px) {
  .departfrom {
    padding: 0px 15px !important;
  }
  .infoSection br {
    display: none;
  }
  .infoSection .departTime {
    margin-top: 45px !important;
  }
  .btnEditSearch span {
    font-size: 16px !important;
  }
  .sortingBox .MuiInput-root {
    .MuiSelect-icon {
      right: -13px !important;
    }
  }
  .sortingBox .MuiInput-root.en-pull-right {
    width: 130px !important;
  }
  .sortingBox .MuiInput-root.mm-pull-right {
    width: 156px !important;
  }
  .myanmarCitizen span {
    padding: 2px !important;
    width: 44% !important;
  }
  .infoSection .airlnelbl {
    position: relative !important;
  }
  .mobile-from-to label {
    width: 22% !important;
  }
  .mdatepicker .SingleDatePicker {
    width: 100% !important;
  }
  .mdatepicker .SingleDatePickerInput_calendarIcon {
    width: 41% !important;
    .fieldLbl {
      width: 105px !important;
      text-align: left;
    }
  }
  .mdatepicker .DateInput {
    width: 59% !important;
  }
  .mdatepickerReturn .DateInput {
    width: 68% !important;
  }
  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 32% !important;
  }
  .mdatepickerReturn .SingleDatePicker {
    width: 80% !important;
  }
  .mdatepickerReturn label {
    width: 12% !important;
    margin-left: -3px !important;
  }
  .infoLbl {
    margin-left: -15px;
  }
  .borderInfo {
    width: 75px !important;
  }
  .btnBlueSearch {
    margin-top: -7vh !important;
  }
  .flag-select .selected--flag--option {
    padding: 0px !important;
  }
  .bgHome .homeContainer .mobile-from-to .MuiFormControl-root {
    width: 78% !important;
  }
  //FOOTER
  .homeContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .hire .ourServiceTitle,
  .hire > span,
  .airlinePartner,
  .airlineFeatured {
    font-size: 21px !important;
  }
  .car-ads-logo img {
    width: 79px;
    height: 88px;
  }
  .adsDesc {
    width: unset;
  }
  .bgHomeService .homeContainer {
    .hire:first-child {
      padding: 20px;
      margin-top: 40px !important;
      img {
        width: 90%;
      }
    }
    .hire:nth-child(2) {
      padding-bottom: 60px;
    }
  }
  .hire .ourServiceTitle {
    margin-top: 50px !important;
  }
  .airlineImg {
    width: 92px;
    height: 30.5px;
    padding: 0 5px;
  }
  .divFeaturedIn {
    padding-top: 30px !important;
  }
  .featuredInContainer {
    .col {
      div {
        width: 100% !important;
      }
    }
  }
  .footer {
    .container .MuiGrid-container {
      padding: 0 20px;
      margin-bottom: 15px;
    }
  }
  .footer-bar {
    flex-direction: column;
    align-items: unset;
    .MuiFormControl-marginNormal {
      margin-bottom: 15px !important;
    }
  }
  .footer-textfield input {
    width: 279px;
    margin-right: 0;
  }
  .footer-subscribe {
    align-self: flex-end;
  }

  .grid-footer {
    margin-bottom: 15px;
  }
}
.flag-select {
  float: right !important;
}
.flag-select .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}
.flag-select .arrow-down {
  color: #fff !important;
}

/*feature image sprite*/
@media (max-width: 768px) {
  .feature-img-ncra {
    background-position: -273px -53px;
    background-size: 685px !important;
    width: 100px !important;
  }
  .feature-img-travel-leisure {
    background-position: 11px -112px !important;
    background-size: 745px !important;
    width: 90px !important;
  }
  .feature-img-irrawaddy {
    background-position: -342px -8px !important;
    background-size: 778px !important;
  }
  .feature-img-yangon-life {
    background-position: -206px -119px !important;
    background-size: 787px !important;
    max-width: 100px;
  }
  .feature-img-irrawady-burma {
    background-position: -1px -10px !important;
    background-size: 922px !important;
  }
  .feature-img-mizzima-burma {
    background-position: 0px -84px !important;
    background-size: 915px !important;
  }
  .feature-img-malay-mail-online {
    background-position: -465px -14px !important;
    background-size: 800px !important;
    max-width: 100px !important;
  }
  .feature-img-sd-asia {
    background-position: -563px -93px !important;
    background-size: 978px !important;
  }
  .feature-img-mizzima-eng {
    background-position: -628px -4px !important;
    background-size: 750px !important;
    width: 100px !important;
  }
  .feature-img-mingalapar {
    background-position: -576px -12px !important;
    background-size: 825px !important;
    width: 100px !important;
  }
  .feature-img-tech-in-asia {
    background-position: -470px -59px !important;
    background-size: 700px !important;
    width: 100px !important;
  }
  /*feature image sprite*/

  /*airline partner image sprite*/
  .divAirlinePartner {
    .bgWhite {
      div {
        width: 184px !important;
      }
    }
  }
  /*airline partner image sprite*/
  .allday-img {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/6f3637e8be7652b1a57b94218e0a9e7c/icon-sprite-min.png");
    background-position: -6px -82px;
    background-size: 187px;
    width: 58px;
    height: 49px;
    float: right;
    margin-right: 5px !important;
  }
}
.returnMobileText {
  margin-left: -31px !important;
}
.tripTypeDefaultText {
  padding-top: 0 !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  border-radius: 5px;
}
@media (max-width: 375px) {
  .search {
    margin-bottom: 3px !important;
  }
  .returnText {
    font-size: 18px !important;
  }
  .stayText {
    font-size: 14px !important;
  }
  .confirmationBox button span {
    font-size: 12px !important;
  }
}
.mobileTripType div {
  padding: 15px 10px !important;
}
@media (max-width: 780px) {
  #DiscoverMyanmarContainer {
    display: none !important;
  }
  #BackgroundContainer .search {
    margin-top: 30px !important;
  }
  .ABTestingLowerPriceDiv {
    width: 100%;
    text-align: center;
  }
  .ABTestingLowerPriceDiv img {
    width: 200px;
    display: none;
  }
  .ABTestingLowerPriceIconUpper {
    width: 200px !important;
    margin-top: 10px !important;
    margin-bottom: 17px !important;
  }
  .ABTestingLowerPriceIconLower {
    width: 200px !important;
    margin-top: -35px !important;
  }
  .ABTestingMobileSwapIcon {
    transform: rotate(90deg) !important;
    left: 80px !important;
    top: -33px !important;
  }

  .ABTestingMobileSwapIcon.MuiSvgIcon-root {
    color: rgb(0, 174, 239) !important;
  }
  .privacyPolicyHeader {
    display: block !important;
    visibility: unset !important;
    .headerLogo {
      display: inline-block !important;
      width: 135px !important;
      padding: 15px 20px;
    }
  }
}

/* Passenger Picker for AB Testing */
@media (max-width: 959px) {
  #ABTestingMobilePassengerType {
    outline: none !important;
  }
  #ABTestingMobilePassengerType .mobile_passenger_type {
    top: 0 !important;
    position: relative !important;
  }
  #ABTestingMobilePassengerType .MuiPaper-elevation1 {
    width: 100% !important;
    text-align: center !important;
  }
  #ABTestingMobilePassengerType .MuiGrid-item {
    width: 100% !important;
    border-bottom: 1px solid #e4e4e4 !important;
    min-height: 20vh !important;
    margin-bottom: 20px !important;
  }
  #ABTestingMobilePassengerType .MuiGrid-item:last-child {
    border-bottom: none !important;
  }
  #ABTestingMobilePassengerType .ageSelect {
    width: 100% !important;
    float: none !important;
  }
  #ABTestingMobilePassengerType .chooseAge {
    width: 100% !important;
    float: none !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input {
    width: 35% !important;
    font-size: 30px !important;
    margin-top: 10px !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input i {
    background-color: rgb(0, 174, 239) !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input b:nth-child(2) i {
    background-color: rgb(0, 174, 239) !important;
  }
  #ABTestingMobilePassengerType .numeric-counter {
    border: none !important;
    width: 100% !important;
    outline: none !important;
    pointer-events: none !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input b {
    border: 1px solid rgb(0, 174, 239) !important;
    border-radius: 50% !important;
    width: 26px !important;
  }
  #ABTestingMobilePassengerType .ageType {
    font-weight: 700 !important;
    font-size: 16px !important;
  }
  .ABTestingMobilePassengerTypeTitle {
    width: 100% !important;
    height: 45px !important;
    background: #000 !important;
    outline: none !important;
    margin-top: 5px !important;
  }
  .ABTestingMobilePassengerTypeTitle span {
    margin-left: -39px !important;
    color: #fff !important;
    line-height: 43px !important;
  }
}
.ABTestingMobilePassengerTypeLable {
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 !important;
  margin-top: 44px !important;
}
.ABTestingMobilePassengerTypeLable span {
  width: 20% !important;
  margin: 0 auto !important;
  padding: 0 !important;
  text-align: center !important;
  color: #fff !important;
  margin-top: -5px !important;
}
#ABTestingMobilePassengerType .closeAiport {
  margin-top: -2px !important;
}
#ABTestingMobilePassengerType .ABTestingMobilePassengerTypeLableText {
  margin-left: 20% !important;
}
/* Passenger Picker for AB Testing */
.ABTestingLoadingDiv {
  width: 100% !important;
  background: #ddd !important;
  color: #000 !important;
  font-size: 14px !important;
  padding: 5px 0px !important;
  font-weight: 500;
}
.ABTestingLoadingTitle {
  width: 93% !important;
  margin: 0 auto !important;
}
.ABTestingLoadingContent {
  margin-top: -10px !important;
}
/* new UI for round trip type */
@media (max-width: 780px) {
  .mmBusLogo img {
    width: 70px !important;
    float: left !important;
  }
  .mmBusLogo span {
    color: #fff !important;
    line-height: 70px;
    float: left !important;
  }
  .tripTypeContainer {
    width: 100% !important;
    height: 45px !important;
    background: #f0f0f0 !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .tripTypeButton {
    width: 50% !important;
    float: left !important;
    font-family: "Roboto Regular" !important;
    font-size: 16px !important;
    line-height: 42px !important;
    text-align: center !important;
    border-color: 2px solid transparent;
    color: #231f20 !important;
    opacity: 0.2 !important;
    cursor: pointer !important;
  }
  .tripTypeButton span {
    width: 100% !important;
    display: inline-block !important;
  }
  .oneWayTripTypeButton {
    border-top-left-radius: 4px !important;
  }
  .activeTripTypeButton {
    background: #ffffff !important;
    font-family: "Roboto Regular" !important;
    color: #00aeef !important;
    border-bottom: 2px solid #00aeef !important;
    height: 43px !important;
    opacity: 1 !important;
  }
  .activeTripTypeButton span {
    font-weight: 700 !important;
  }
  .nonActiveTripTypeButton {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }
  .myanmarCitizen {
    margin-top: -60px !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .roundTripTypeButton {
    border-top-right-radius: 4px !important;
  }
  #BackgroundContainer .search {
    margin-top: -5vh !important;
  }
  .departfrom {
    height: 45px !important;
    margin-top: -15px !important;
  }
  .mdatepicker .SingleDatePicker .SingleDatePickerInput {
    line-height: 30px !important;
  }
  .mobile-from-to {
    margin-top: 10px !important;
  }
  .fieldLbl {
    line-height: 30px !important;
  }
  .mdatepicker,
  .mdatepickerReturn {
    border-bottom: none !important;
  }
  .passenger {
    padding-top: 7px !important;
    font-size: 16px !important;
  }
  #BackgroundContainer .swapDestination {
    left: 25% !important;
    top: -23px !important;
  }
  .swapDestination.MuiSvgIcon-root {
    font-size: 1.2rem !important;
    transform: rotate(90deg) !important;
  }
  .mobile-from-to label,
  .passenger label,
  #BackgroundContainer .fieldLbl {
    font-size: 14px !important;
  }
  .mobile-from-to .MuiInputBase-input,
  .homeContainer .flightSearch .DateInput_input {
    font-size: 18px !important;
  }

  .roundTripPassengerType {
    margin-bottom: -20px !important;
  }
  .nationality-radio-btn {
    width: 74px !important;
  }
  .myanmarCitizen label span {
    width: 4% !important;
  }
  .myanmarCitizen .MuiFormControlLabel-root {
    padding-right: 0 !important;
  }
  .homeContainer {
    height: 107vh !important;
  }
  .infoSection span {
    display: block !important;
  }
  .chooseFlightTitle {
    margin-bottom: -20px !important;
  }
}
@media (max-width: 370px) {
  .myanmarCitizen span {
    padding: 4px !important;
    width: 42% !important;
  }
  .myanmarCitizen .MuiFormControlLabel-root,
  .passengerType .MuiFormControlLabel-root {
    margin-right: -5px !important;
  }
}
/* new UI for round trip type */
/* MMbus ticket logo at home page of bus */

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  .homeBusDivMobile {
    margin-top: -45% !important;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  .homeBusDivMobile {
    margin-top: -85% !important;
  }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
  .homeBusDivMobile {
    margin-top: -100% !important;
  }
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) {
  .homeBusDivMobile {
    margin-top: -95% !important;
  }
}

/* Note 3 */
@media only screen and (min-device-width: 400px) and (max-device-height: 700px) {
  .homeBusDivMobile {
    margin-top: -100% !important;
  }
}
/* Galaxy Note 3 */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) {
  .homeBusDivMobile {
    margin-top: -60% !important;
  }
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-height: 1024px) {
  .homeBusDivMobile {
    margin-top: -180% !important;
  }
}
/* MMbus ticket logo at home page of bus */
/* new design for language flag */
@media (max-width: 780px) {
  #homeFlag {
    width: 20% !important;
    float: right !important;
    margin-top: 6% !important;
    font-size: 14px !important;
    margin-left: 0 !important;
  }
  #areaCode {
    width: 100% !important;
    float: left !important;
  }
  .mobileFlagWrapper {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 20px;
  }
  .mobileFlagOptionList img {
    margin-left: -3px !important;
    margin-right: 4px !important;
  }
  .mobileFlagOptionListContainer div:nth-child(1) {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .mobileFlagOptionListContainer div:nth-child(2) {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .progressbar {
    width: 90% !important;
    justify-content: left !important;
    float: left !important;
  }
  .language-select {
    width: 10% !important;
    float: left !important;
  }
}
/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  #homeFlag {
    width: 26% !important;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  #homeFlag {
    width: 22% !important;
  }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
  #homeFlag {
    width: 20% !important;
  }
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) {
  #homeFlag {
    width: 22% !important;
  }
}

/* Note 3 */
@media only screen and (min-device-width: 400px) and (max-device-height: 700px) {
  #homeFlag {
    width: 21% !important;
  }
}
/* Galaxy Note 3 */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) {
  #homeFlag {
    width: 22% !important;
  }
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-height: 1024px) {
  #homeFlag {
    width: 12% !important;
  }
}
/* new design for language flag */
/* new UI design for depart flight div at flight result page */
@media (max-width: 780px) {
  .departFlightMobileDiv img {
    width: 30px !important;
    float: left !important;
  }
  .departFlightMobileDiv span {
    line-height: 25px;
  }
  .departFlightMobileText {
    width: 100%;
    border: 1px solid #00aeef;
    padding: 3px 7px;
    font-size: 12px;
    color: #00aeef;
  }
  .departFlightMobileArrow {
    width: 50% !important;
  }
  .departFlightMobileTime {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .departFlightMobileDate {
    font-size: 12px !important;
  }
  .departFlightMobileTimeDiv {
    clear: both !important;
  }
  .departFlightMobileCity {
    font-size: 12px !important;
    color: #7f91a8 !important;
  }
  .departFlightMobileAirline {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 20px !important;
  }
  .departFlightMobileClass {
    font-size: 12px !important;
    color: #7f91a8 !important;
  }
  .departFlightMobileAirlineDiv {
    margin-top: 5px !important;
  }
  .departFlightMobileAirlineLogo {
    margin-top: -3px !important;
  }
  .departFlightMobileAirlineLogoDiv img {
    margin-right: 5px !important;
  }
  .departFlightMobileDivLogo {
    text-align: center !important;
  }
  .departFlightMobileDivLogo img {
    float: none !important;
    margin-bottom: -8px !important;
  }
}
/* new UI design for depart flight div at flight result page */
/* new UI for flight details of customer information page */
@media only screen and (max-device-width: 780px) {
  .flightDetailBox {
    margin-bottom: -10px !important;
  }
  .flightDetailBoxReturnDiv {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom: 1px dotted #e6e7e8 !important;
  }
  .flightDetailBoxLowerDiv {
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
  }
  .custDetailTitle {
    border-bottom: none !important;
  }
  .custDetailTitleMobile {
    margin-top: -8px !important;
  }
  .flightDetailTitleMobile {
    border-top: 1px dotted #e6e7e8 !important;
    border-bottom: none !important;
  }
  .flightDetailTitleMobile img {
    width: 60% !important;
  }
  .flightDetailIconMobile {
    float: left !important;
    text-align: center !important;
    margin-top: -5px !important;
  }
  .flightDetailIconMobile img {
    margin-bottom: -8px !important;
    width: 50% !important;
  }
  .flightDetailRouteMobile {
    float: left !important;
    padding-left: 10px !important;
  }
  .flightDetailRouteNameMobile span {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .flightDetailRouteDateMobile span {
    font-size: 12px !important;
    line-height: 37px !important;
  }
  .flightDetailNameMobile img {
    width: 12% !important;
    float: left !important;
  }
  .flightDetailNameMobile span {
    font-size: 14px !important;
    margin-left: 5px !important;
    float: left !important;
    line-height: 32px !important;
    font-weight: bold !important;
  }
  .flightDetailClockMobile {
    float: left !important;
    font-size: 14px !important;
    text-align: right !important;
  }
  .flightDetailStatusMobile {
    float: left !important;
    text-align: center !important;
  }
  .flightDetailStatusMobile img {
    margin-right: 10px !important;
  }
  .flightDetailDepartCityMobile {
    float: left !important;
    font-size: 14px !important;
  }
  .returnflightDurationMobile {
    border-bottom: 1px dotted #e6e7e8 !important;
  }
  .flightDetailDepartAirportMobile {
    font-size: 10px !important;
  }
  .flightDetailDepartMiddleMobile {
    clear: both !important;
    width: 100% !important;
    margin: 35px auto 0 auto !important;
  }
  .departurePoint {
    height: 8px;
    width: 8px;
    border: 1px solid #00aeef;
    border-radius: 50%;
    display: inline-block;
  }
  .arrivalPoint {
    height: 10px;
    width: 10px;
    background-color: #00aeef;
    border-radius: 50%;
    display: inline-block;
  }
  .routeLine {
    border-left: 2px solid #e6e7e8;
    height: 40px;
    position: absolute;
    margin-top: -18px !important;
    margin-left: -1px !important;
  }
  .flightDetailDurationMobile {
    font-size: 12px !important;
  }
  .oneWidthMobileDiv {
    min-width: 8.33% !important;
    margin-left: 20px !important;
  }
  .tenWidthMobileDiv {
    min-width: 8.33% !important;
    margin-left: 5px !important;
  }
  .continueBox {
    background: none !important;
    border: none !important;
  }
  .flightMobileAirportName {
    font-weight: 500 !important;
  }
}
@media only screen and (max-device-width: 320px) {
  .flightDetailNameMobile span {
    font-size: 12px !important;
    line-height: 23px !important;
  }
  .flightDetailDurationMobile {
    font-size: 11px !important;
  }
}
@media only screen and (min-device-width: 360px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }
}
@media only screen and (min-device-width: 375px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }
}
@media only screen and (min-device-width: 400px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }
  .flightDetailIconMobile img {
    width: 50% !important;
  }
}
@media only screen and (min-device-width: 414px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }
  .flightDetailIconMobile img {
    width: 45% !important;
  }
}
/* new UI for flight details of customer information page */
@media only screen and (max-device-width: 780px) {
  #ABTestingMobilePassengerType .react-numeric-input b {
    margin-top: -10px !important;
    width: 40px !important;
    height: 40px !important;
    background-size: cover !important;
    border: none !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input b:nth-child(3) {
    background-image: url(https://images.ctfassets.net/7rpik7r2pwv0/6rWH8SPYQ5xbHVLPTybjrE/d7f978c43cbc4e73e6de4d7dab0a39e5/passenger-picker-decrease-icon.png) !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input b:nth-child(2) {
    background-image: url(https://images.ctfassets.net/7rpik7r2pwv0/32II2GYDbZhSSdSPNDB44q/c7aedd2febdec5d25cfe248252db1ddb/passenger-picker-increase-icon.png) !important;
  }
  .chooseAge .numeric-counter {
    width: 40px !important;
    margin-top: -8px !important;
    height: 40px !important;
  }
  #ABTestingMobilePassengerType .react-numeric-input {
    width: 45% !important;
  }
}
@media (max-width: 780px) {
  .btnBlueSearch button {
    height: 60px !important;
  }
  #fl-home-btn-search span {
    font-weight: 500 !important;
  }
  .BusCenterInfo {
    display: none !important;
  }
}
.bookedSeat span {
  color: #ffffff !important;
}
.avaText {
  color: #000000 !important;
}
.selectText {
  color: #ffffff !important;
}
@media (max-width: 780px) {
  .busMobileMyanmarCitizenLable {
    font-weight: 700 !important;
  }
  #bs-home-btn-search span {
    font-weight: 500 !important;
  }
  #mobileBusHomeSearchBox {
    margin-top: 5vh !important;
    margin-bottom: 0 !important;
  }
  .busSearchMobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .busSearchMobile button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mobileBusHomeHeader {
    min-height: 300px !important;
  }
  #bgBus .homeContainer {
    height: auto !important;
  }
  #bgBus .btnBlueSearch {
    margin-top: 20px !important;
  }
  .bgBus {
    background-size: 100% 60% !important;
    background-position: top !important;
    background-color: #000000 !important;
  }
  .mobileBusDefaultText {
    color: #a5a7a6 !important;
  }
  .mmBusLogo span {
    font-size: 10px !important;
  }
  .mmBusLogo img {
    width: 50px !important;
  }
  .airportList {
    color: #000000 !important;
  }
  .airportList span {
    color: #a5a7a6 !important;
  }
  .mmBusLogo span {
    line-height: 50px !important;
    margin-right: 5px !important;
  }
  .mobile-from-to label {
    width: 20% !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen {
    height: 60px !important;
    margin-top: -75px !important;
    line-height: 60px !important;
  }
  #mobileBusHomeSearchBox .popupSearch .MuiTextField-root {
    width: 93% !important;
    left: -5px !important;
  }
  #mobileBusHomeSearchBox .popupSearch .MuiButton-text {
    margin-left: -12px;
  }
  #mobileBusHomeSearchBox .popupSearch .MuiInputBase-root {
    padding: 0 !important;
  }
  #mobileBusHomeSearchBox .popupSearch .searchTitle {
    left: 0 !important;
  }
  #mobileBusHomeSearchBox .airportList {
    font-size: 16px !important;
  }
}
@media (max-width: 320px) and (max-height: 568px) {
  #mobileBusHomeSearchBox .myanmarCitizen span {
    font-size: 15px !important;
  }
  .mobileBusHomeHeader {
    min-height: 150px !important;
  }
  .mobile-from-to label {
    width: 25% !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen {
    line-height: 50px !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen label {
    margin-top: 13px;
  }
}
@media (max-width: 375px) and (max-height: 667px) {
  .mobileBusHomeHeader {
    min-height: 150px !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen label {
    margin-top: 13px;
  }
}
@media (max-width: 414px) and (max-height: 736px) {
  .mobileBusHomeHeader {
    min-height: 150px !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen label {
    margin-top: 13px;
  }
}
@media (max-width: 375px) and (max-height: 812px) {
  .mobileBusHomeHeader {
    min-height: 175px !important;
  }
  .bgBus {
    height: 90vh !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen label {
    margin-top: 13px;
  }
}
@media (max-width: 768px) and (max-height: 1024px) {
  .flag-select {
    display: none !important;
  }
}
@media (max-width: 400px) and (max-height: 522px) {
  .mobileBusHomeHeader {
    min-height: 130px !important;
  }
}
@media (max-width: 360px) and (max-height: 640px) {
  .mobileBusHomeHeader {
    min-height: 170px !important;
  }
  #mobileBusHomeSearchBox .myanmarCitizen {
    line-height: 50px !important;
  }
}
@media (max-width: 411px) and (max-height: 731px) {
  .mobileBusHomeHeader {
    min-height: 155px !important;
  }
}
@media (max-width: 411px) and (max-height: 823px) {
  .mobileBusHomeHeader {
    min-height: 155px !important;
  }
}
@media (max-width: 780px) {
  .myanmarCitizen span {
    width: 50% !important;
  }
  .departfrom {
    padding: 0 16px !important;
  }
  .btnEditSearchMobile {
    margin-left: -7px !important;
    margin-top: 6px !important;
  }
  .busSelectMobile h4 {
    font-weight: 200 !important;
  }
  .btnContinue {
    height: 60px !important;
  }
  .mobileBusSeatHeaderText {
    width: 91% !important;
    margin: 0 auto !important;
    min-height: 35px !important;
    margin-bottom: 10px !important;
  }
  .mobileBusSeatHeaderText span {
    font-size: 14px;
    color: #ffffff !important;
  }
  .busSeatListBullet {
    margin: 0px 5px !important;
    font-size: 20px !important;
    margin-top: -2px !important;
  }
  #mobileBusPhonePrefix {
    margin-top: 10px !important;
  }
  #mobileBusPhonePrefix .flag-dropdown {
    border: none !important;
  }
  .contactMobileBus .mobileNumberDiv label {
    margin-left: -79px !important;
  }
  #mobileBusPhonePrefix .selected-flag {
    margin-left: -5px !important;
  }
  .contactMobileBus .mobileNumberDiv {
    width: 75% !important;
  }
  .mobileConfirmBtn {
    height: 37px !important;
  }
  .busMobileMyanmarCitizenLable {
    padding-left: 10px !important;
  }
  .textAlignLeft {
    text-align: left !important;
  }
  .textAlignRight {
    text-align: right !important;
  }
  .mobileBusConfirmButton {
    height: 45px !important;
  }
  .imgOpeartor {
    float: none !important;
    margin-left: 0 !important;
  }
  #payment-input-cardHolderName {
    min-height: 21px !important;
    font-size: 16px !important;
    color: #424770 !important;
  }
  .mobileBusPayCounter {
    background: #f4f3f4 !important;
  }
  .paymentPage .contactInfo {
    padding: 10px 20px !important;
  }
  .mobileBusPaymentTypeBox .title {
    margin-left: 0 !important;
  }
  .mobileBusCardBox {
    border: 1px solid #e6e7e8 !important;
    box-shadow: none !important;
  }
  .mobileBusConfirmationDateText {
    font-size: 12px !important;
  }
  .mobileBusConfirmationClassText {
    margin: 5px 0 !important;
  }
}
#bgBus .CalendarDay__default.CalendarDay__today {
  color: #000000;
  border: none !important;
  border-radius: 6px !important;
}
#bgBus .CalendarDay__default.CalendarDay__today:hover {
  color: #ffffff !important;
}
#bgBus .CalendarDay__selected {
  color: #ffffff !important;
  border: none !important;
  border-radius: 6px !important;
  outline: none !important;
}
#bgBus .CalendarDay__default.CalendarDay__today {
  color: #000000;
  border: none !important;
  border-radius: 6px !important;
}
#bgBus .CalendarDay__default.CalendarDay__today:hover {
  color: #ffffff !important;
}
#bgBus .CalendarDay__selected {
  color: #ffffff !important;
  border: none !important;
  border-radius: 6px !important;
  outline: none !important;
}
.abtHomeMenu {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  float: left;
  width: 100%; // opacity: 0.8;
  padding: 10px;
  height: 92px;
  margin-top: -25% !important;
  div {
    text-align: center;
  }
  .menuDiv {
    background-color: #fff;
    border-radius: 55px;
    width: 50px;
    text-align: center;
    margin: auto;
    margin-bottom: 5px !important;
    height: 50px;
  }
  .activeMenu {
    .menuDiv {
      background-color: #f7911e !important;
    }
  }
  img {
    width: 35px;
    margin: auto;
    padding: 7px;
  }
}
.abtMobileMenu .btnBlueSearch {
  margin-top: -15.7vh !important;
}
@media (max-width: 780px) {
  .abtMobileMenu .myanmarCitizen {
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-radius: 4px !important;
    // text-align: center;
    margin-top: -55px !important;
    span.busMobileMyanmarCitizenLable {
      padding-left: 15px !important;
      width: 45% !important;
      margin: auto 0px !important;
      line-height: 30px;
    }
    .MuiFormControlLabel-root {
      margin-right: -10px !important;
    }
  }
  .abtMobileMenu .flightMenu {
    margin-top: -13vh;
    position: absolute;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    width: 90%;
  }
  #BackgroundContainer .abtHomeMenu a > span {
    color: #fff;
    font-weight: 400 !important;
  }
  #BackgroundContainer .abtHomeMenu .activeMenu span {
    font-weight: 500 !important;
  }
  .oneWayLbl {
    text-align: right;
    font-size: 14px;
  }
  .fieldLbl.oneWayLbl {
    line-height: 37px !important;
  }
}
@media (max-width: 320px) {
  .search {
    height: 270px !important;
  }
  .abtHomeMenu img {
    width: 30px !important;
  }
  .abtHomeMenu .menuDiv {
    width: 40px;
    height: 40px;
  }
  .abtHomeMenu {
    height: 80px;
  }
}
.menuBlueDiv {
  background-color: #f0f0f0 !important;
  border-radius: 12px;
  float: left;
  padding: 20px 0px;
  overflow: hidden;
  width: 47%;
  margin: auto 5px;
  text-align: center;
  img {
    width: 45px;
  }
  span {
    color: #050302;
    font-weight: 400;
    font-size: 18px;
    padding-left: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -38px;
  }
}
.activeMenu .menuBlueDiv {
  background-color: #c9eaff !important;
  span {
    font-weight: 600 !important;
  }
}
.homeMenuDiv {
  margin-top: -60px !important;
}
@media (max-width: 360px) {
  .menuBlueDiv {
    width: 46.5% !important;
  }
}
