.SpacexLogin {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #273142;
}

.SpacexLogin #main-holder {
  width: 400px;
  height: 400px;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: transparent;
  border-radius: 7px;
  /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1); */
}

.SpacexLogin #login-error-msg-holder {
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
}

.SpacexLogin #login-error-msg {
  text-align: left;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #8a0000;
}

.SpacexLogin #error-msg-second-line {
  display: block;
}

.SpacexLogin #login-form {
  display: grid;
  justify-items: center;
  align-items: center;
}

.SpacexLogin .login-form-field::placeholder {
  color: #3a3a3a;
}

.SpacexLogin .login-form-field {
  border: none;
  border-bottom: 1px solid #3a3a3a;
  margin: 0 2px;
  outline: none;
  padding: 8px 10px;
  width: 230px;
  font-size: 16px;
  border-radius: 5px;
}

.SpacexLogin #code-field {
  margin-top: 10px;
}

.SpacexLogin #login-form-submit {
  width: 100%;
  padding: 7px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: #2cafe8;
  cursor: pointer;
  outline: none;
  font-size: 1.1rem;
  margin-top: 20px;
}

.SpacexLogin #login-form-submit:disabled {
  opacity: 0.8;
}

.SpacexLogin .saml-form {
  width: 80%;
  font-family: "Roboto" !important;
  text-align: center;
}
.SpacexLogin .saml-btn {
  width: 85%;
  padding: 6px 7px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: #2cafe8;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  text-transform: initial;
  font-family: "Roboto" !important;
}
.SpacexLogin .saml-btn:hover {
  color: white;
  font-weight: bold;
  background-color: #2cafe8;
}
.SpacexLogin .saml-text {
  color: #fff;
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 0px;
  text-align: center;
}
.SpacexLogin .saml-span {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 375px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .SpacexLogin {
    min-height: 100vh !important;
    min-height: -webkit-fill-available;
  }
  .SpacexLogin .saml-btn {
    width: 86%;
  }
  .SpacexLogin .saml-text {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .SpacexLogin {
    min-height: 100vh !important;
  }
}
