.spacex-important-info {
  .homeContainer {
    min-height: 100vh;
    height: auto !important;
  }
  .contentEdit {
    margin-top: 20px;
    padding: 20px;
    width: 100%;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    background-color: #120a38;
    outline: none;
  }
  .contentEdit:hover,
  .contentEdit:focus {
    border: 1px solid #fff;
  }
  .quill {
    width: 100%;
    min-height: 80vh;
    .ql-container {
      min-height: 80vh;
      .ql-editor {
        min-height: 80vh;
      }
    }
  }
  .ql-formats {
    svg .ql-stroke {
      stroke: #fff !important;
    }
    svg .ql-fill {
      fill: #fff !important;
    }
  }
  .ql-picker,
  .ql-editor,
  .ql-picker .ql-picker-label:hover,
  .ql-picker .ql-picker-label .ql-active {
    color: #fff !important;
  }
  .ql-picker-options span {
    color: #000;
  }

  .nav-title {
    width: 80%;
    ul {
      list-style: none;
      color: #fff;
      padding: 0;
      li {
        line-height: 25px;
        padding: 5px 10px;
        margin-bottom: 10px;
        font-size: 15px;
      }
      li:hover {
        border-radius: 5px;
        cursor: pointer;
        background-color: #0e2237;
      }
      li.active {
        border-radius: 5px;
        cursor: pointer;
        color: rgb(165, 216, 255);
        background-color: #14385c;
      }
    }
  }
}

@media (max-width: 780px) {
  .spacex-important-info {
    .quill {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .spacex-important-info {
    pointer-events: none;
  }
}
