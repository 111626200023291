$black: #000;
$white: #fff;
$fontmedium: "Roboto", Myanmar3 !important;

.confirmationText {
  font-family: $fontmedium;
  font-weight: 500;
  font-size: 20px;
}

.confirmation-upper {
  text-align: center;
  width: 100%;
  img {
    margin: 50px 0 20px 0;
  }
  .title {
    color: $black;
    font-family: $fontmedium;
    font-weight: 500;
    font-size: 22px;
    display: block;
    margin-bottom: 15px;
  }
  .bodytext {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 18px;
    color: #4e4e4e;
  }
}

.middleregion {
  //padding-right: 5px;
  // border-right: 1px dashed #707070;
  // float: left;
  // width: 75%;
  box-sizing: border-box;
  @media (max-width: 760px) {
    width: 100%;
    float: none;
    border-right: 0px;
    padding-right: 0px;
    border-bottom: 1px dashed #707070;
  }
}

.thirdregion {
  padding: 0 0 0 30px;
  float: left;
  width: 25%;
  box-sizing: border-box;
  @media (max-width: 760px) {
    width: 100%;
    float: none;
    padding-left: 0px;
  }
}

.confirmation-lower {
  width: 75% !important;
  margin: 0 auto;
  border-radius: 5px;
  background-color: $white;
  margin-top: 30px;
  padding: 20px 10px 10px 10px;
  overflow: hidden;
  img {
    width: 100px;
  }
  .total {
    margin-left: -10px;
    width: 75%;
    margin-top: 20px;
    background: linear-gradient(270deg, #0e99eb, #46c4f4 100%);
    padding: 5px 10px 5px 10px;
    border-radius: 0px 5px 5px 0px;
    text-align: right;

    .total-text {
      font-size: 11px;
      color: $white;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
    }
    .total-amt {
      font-size: 23px;
      color: $white;
      font-family: "Roboto" !important;
      font-weight: 400;
    }
  }
  #detaildiv-datetime {
    display: flex;
    @media (max-width: 760px) {
      display: block;
    }
  }
  .detaildiv {
    margin-bottom: 30px;
    width: 100%;
    display: block;
    @media (max-width: 760px) {
      margin-bottom: 0;
    }
    .detailheader-region {
      width: 35%;
      text-align: right;
      display: inline-block;
      margin-right: 30px;

      @media (max-width: 760px) {
        width: 100%;
        margin: 0px;
        text-align: center;
        margin-bottom: 10px !important;
      }
      .detailheader {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 14px;
        color: $black;
      }
      .flightname {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 13px;
        color: #8f8f8f;
      }
      .duration {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 14px;
        color: $black;
      }
    }
    .detailtext-region {
      width: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 760px) {
        margin: 0px;
        text-align: center;
        box-sizing: border-box;
        padding: 0px !important;
        width: initial;
        justify-content: center;
      }
      img {
        width: 22px;
        height: 16px;
        display: inline-block;
        margin: 0 20px;
      }
      .contact {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 14px;
      }
      .durationinfo {
        display: inline-block;
        width: 50%;
        font-family: $fontmedium;
        font-weight: 500;
        span {
          white-space: nowrap;
        }
        .time {
          font-size: 14px;
          color: $black;
          font-family: $fontmedium;
          font-weight: 500;
        }
        .date {
          font-size: 14px;
          font-weight: 500;
          color: $black;
        }
        .place {
          font-weight: 500;
          font-size: 14px;
          color: #919191;
        }
        @media (max-width: 760px) {
          width: 50%;
          margin: 0 10px;
        }
      }
      .fromtoinfo {
        span {
          white-space: nowrap;
          display: block;
          text-align: center;
        }
        .short-name {
          font-weight: 500;
          font-size: 14px;
          color: $black;
        }
        .long-name {
          font-weight: 500;
          font-size: 14px;
          color: #919191;
        }
      }
    }
  }
  .bookingdetail {
    .bookingdiv {
      margin-bottom: 15px;
      .bookingheader {
        font-family: "Roboto", Myanmar3 !important;
        font-weight: 700;
        font-size: 14px;
        display: block;
      }
      .bookingid {
        font-family: "Roboto", Myanmar3 !important;
        font-weight: 700;
        font-size: 20px;
        color: #00a651;
      }
      .bookingtext {
        font-family: "Roboto", Myanmar3 !important;
        font-size: 14px;
      }
    }
  }
}
.confirmation-lower.returnFlightInfo {
  border-top: 1px dashed #6f6f6f;
  margin-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin-bottom: 30px;
}
@media (max-width: 760px) {
  .confirmation-lower {
    width: calc(100% - 20px) !important;
    margin-top: 0px !important;
    padding: 0px 10px !important;
    box-sizing: border-box;
    margin: 0px 10px;
    margin-left: auto;
    margin-right: auto;
    .returnFlightInfo {
      border-bottom: 1px dashed #6f6f6f;
    }
    .bookingdetail {
      .bookingdiv {
        text-align: center !important;
        .bookingheader {
          line-height: 30px;
          font-weight: 700;
        }
      }
    }
  }
  .confirmation-lower .total {
    width: 100% !important;
    margin-left: -30px !important;
    margin-top: 0px !important;
  }
  .confirmation-upper {
    background-color: transparent;
  }
  .confirmation-upper .title {
    font-size: 16px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
  .confirmation-upper .bodytext {
    font-size: 14px !important;
  }

  .detaildiv {
    text-align: center;
    padding: 20px 0px;
  }

  .detailheader {
    font-size: 14px;
    color: $black;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .airlineDetailHeader {
    font-family: $fontmedium;
    font-weight: 500;
    font-size: 14px;
    color: $black;
    line-height: 30px;
  }
  .short-name {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 25px;
    color: $black;
  }
  .long-name {
    font-family: "Roboto" !important;
    font-weight: 500;
    font-size: 13px;
    color: #8f8f8f;
  }
  .confirmation-lower img {
    width: 30px !important;
  }
  .confirmation-upper {
    padding: 20px 0px 0px 0px;
    img {
      margin: 0px !important;
    }
  }
  .detailtext-region {
    width: 100%;
    margin-top: 0px !important;
    padding: 20px !important;
    margin-top: 20px;
    img {
      margin: 10px 20px 10px 15px;
    }
  }
  .emailContact {
    margin-top: 30px;
  }
  .airlineInfoDiv {
    background-color: $white;
    padding: 20px;
  }
  .airlineConfirmation {
    float: right;
    text-align: right;
    img {
      width: 80% !important;
    }
  }
  .time {
    font-size: 16px;
    color: $black;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
  }
  .bookingtext {
    color: #8f8f8f;
    font-family: "Roboto" !important;
    font-weight: 500;
    font-size: 13px;
  }
  .durationinfo {
    img {
      margin: 0px !important;
    }
  }
  .bgWhite {
    background-color: #fff;
    padding-bottom: 50px;
  }
  .playArrow {
    width: 10px;
    padding: 6px !important;
  }
  .progressbar li {
    min-width: 90px !important;
    position: relative;
  }
  .step-info {
    font-size: 12px !important;
  }
  .imgOperator {
    border: 1px solid #e6e7e8;
    border-radius: 5px;
    margin: 5px;
  }
  .mobileBusConfirmationDurationText {
    float: left !important;
    margin-right: 20px !important;
  }
  .mobileBusConfirmationDurationText span,
  .mobileBusConfirmationSmallText span {
    font-size: 12px !important;
    color: #404040 !important;
  }
  .mobileBusConfirmationSeatText {
    font-size: 20px !important;
    color: #00aeef !important;
    margin-left: 5px !important;
  }
  .mobileBusConfirmationSmallTextDiv {
    margin: 10px 0 !important;
  }
  .mobileBusConfirmationRouteText span {
    margin-left: 0 !important;
  }
  .mobileBusConfirmationFourDiv {
    width: 22% !important;
  }
  .mobileBusConfirmationRouteLineDiv {
    min-height: 5px !important;
  }
  .mobileBusConfirmationRouteLineDiv .routeLine {
    margin-top: 0 !important;
  }
  .mobileBusConfirmationClockText {
    text-align: left !important;
  }
  .mobileBusConfirmationClockText span {
    font-weight: 500 !important;
  }

  .mobileBusConfirmationClassText span {
    font-weight: 400 !important;
  }
  .mobileBusConfirmationLeftText {
    text-align: left !important;
  }
  .mobileBusConfirmationLeftText span {
    padding-left: 0 !important;
  }
  .mobileBusConfirmationRightText {
    text-align: right !important;
  }
  .mobileBusConfirmationImageDiv {
    padding-left: 0 !important;
  }
  .flightDetailTitleMobile {
    border-top: 1px dashed #e6e7e8 !important;
  }
  #mobileFlightConfirmation .mobileBusConfirmationReservationDiv {
    border-top: none !important;
    border-bottom: 1px dashed #e6e7e8 !important;
  }
}
.confirmation-lower .bookingdetail .bookingdiv.textCenter {
  text-align: center !important;
  padding-top: 10px !important;
}
.confirmationBorder {
  border-bottom: 1px dashed #6f6f6f;
}
.returnFlightInfo {
  padding-bottom: 15px;
  .totalPrice {
    margin: auto;
    width: 180px;
    margin-top: 20px;
    background: linear-gradient(270deg, #0e99eb, #46c4f4 100%);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    line-height: 20px;

    .total-text {
      font-size: 11px;
      color: $white;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
    }
    .total-amount {
      font-size: 20px;
      color: $white;
      font-family: "Roboto" !important;
      font-weight: 400;
    }
  }
}
.flightTripType {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.returnFlightInfo {
  img.airlineLogo {
    width: 150px !important;
    float: none !important;
    padding-top: 20px;
  }
}
.roundTripTotal {
  text-align: center;
  border-top: 1px dashed #000;
  margin-top: 10px !important;
}
.airlineLogoDiv {
  padding: 10px;
}
/* new design for confirmation page of flight */
@media only screen and (max-width: 780px) {
  #mobileFlightPayment .flightDetailBox {
    margin-top: 5px !important;
  }
  #mobileFlightConfirmation .flightConfirmationMobileEmail {
    font-size: 16px !important;
    color: #00aeef !important;
  }
  #mobileFlightConfirmation .totalPrice {
    background: none !important;
    text-align: left !important;
    width: 100% !important;
    margin-top: 15px !important;
  }
  #mobileFlightConfirmation .flightConfirmationTotalText {
    width: 50% !important;
    float: left !important;
    margin-top: -10px !important;
    margin-bottom: 10px !important;
  }
  #mobileFlightConfirmation .flightConfirmationTotalPrice {
    margin-top: -10px !important;
    margin-bottom: 10px !important;
  }
  #mobileFlightConfirmation .flightConfirmationTotalPrice {
    width: 50% !important;
    float: left !important;
  }
  #mobileFlightConfirmation .total-text {
    width: 100% !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  #mobileFlightConfirmation .total-amt {
    color: #1e9b1e !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationContainer {
    background: #f4f3f4 !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationMessageDiv {
    border: 1px solid #e4e4e4;
    margin: 15px !important;
    border-radius: 4px !important;
  }
  #mobileFlightConfirmation .flightDetailBox {
    border-bottom: 1px solid #e6e7e8 !important;
  }
  #mobileFlightConfirmation .returnflightDurationMobile {
    border-bottom: none !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationDepart {
    margin-bottom: -5px !important;
  }
  #mobileFlightConfirmation .returnFlightInfo {
    border-bottom: none !important;
    border-top: 1px dotted #e6e7e8 !important;
    width: 100% !important;
  }
  #mobileFlightConfirmation .emailContact {
    margin-top: 10px !important;
  }
  #mobileFlightConfirmation .flightDetailTitleMobile {
    max-width: 90% !important;
    margin: 0 auto !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationDepart {
    border-bottom: 1px solid #e6e7e8;
    min-height: 40px !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationReservation {
    line-height: 50px !important;
    margin-bottom: 5px !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationTitleDiv {
    background: none !important;
    text-align: left !important;
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationTripDiv {
    background: none !important;
    text-align: left !important;
    margin-top: -20px !important;
    margin-bottom: -10px !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationTitleDiv span,
  #mobileFlightConfirmation .mobileFlightConfirmationTripDiv span {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationReservation div {
    width: 50% !important;
    float: left !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationReservation div span {
    padding-left: 15px;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationBookNew {
    background: #00aeef !important;
    border-radius: 4px !important;
    text-align: center !important;
    margin-top: 20px !important;
    cursor: pointer !important;
  }
  #mobileFlightConfirmation .mobileFlightConfirmationBookNew span {
    color: #fff !important;
    font-size: 18px !important;
    line-height: 40px !important;
  }
  .returnFlightInfo {
    padding-bottom: 0 !important;
  }
}
.busConfirmation {
  margin-top: 20px !important;
}
@media only screen and (max-width: 320px) {
  #mobileFlightConfirmation .confirmation-upper .title {
    font-size: 15px !important;
  }
  #mobileFlightConfirmationDepartBaggage span,
  #mobileFlightConfirmationReturnBaggage span {
    font-size: 10.8px !important;
  }
}
/* new design for confirmation page of flight */
