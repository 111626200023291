#sig-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.SignaturePage {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: #273142;
}

.SignaturePage .sig-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  /* min-width: 400px;
  max-width: 600px; */
  width: 667px;
  max-width: 667px;
}

.SignaturePage .sig-wrapper > * {
  margin: 10px 0;
}

.SignaturePage .title {
  color: #fff;
  margin-top: 0;
}

.SignaturePage .terms {
  width: 100%;
  min-height: 180px;
  max-height: 180px;
  padding: 15px;
  font-size: 14px;
  box-sizing: border-box;
  font-family: "Roboto", Myanmar3 !important;
  border-radius: 5px;
  color: #231f20;
  line-height: 1.35;
  background-color: #fff;
  overflow-y: auto;
}

.SignaturePage .btn-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0;
}

.SignaturePage .sign-btn,
.theme-btn {
  font-family: Roboto, sans-serif !important;
  padding: 6px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-color: #2cafe8;
  outline: none;
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1.75;
}
.SignaturePage .sign-btn:disabled,
.theme-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.SignaturePage .signatureCanvas,
.SignaturePage .canvas-wrapper {
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

.SignaturePage .canvas-wrapper {
  background-color: #fff;
  position: relative;
}

.SignaturePage .canvas-wrapper span.sign-here {
  position: absolute;
  display: block;
  text-align: center;
  color: #27314277;
  z-index: 1;
  font-family: Roboto, Myanmar3;
  font-size: 18px;
  top: calc(50% - 11px);
  left: 0;
  right: 0;
}

.SignaturePage .signatureCanvas {
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.SignaturePage .desc {
  color: #abaeaf;
  box-sizing: border-box;
  line-height: 23px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

@media (min-width: 320px) {
  .SignaturePage {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  .SignaturePage .sig-wrapper > * {
    margin: 7px 0;
  }
  .SignaturePage .title {
    padding-left: 10px;
    margin-top: 0;
    line-height: 22px;
    font-size: 20px;
  }
  .SignaturePage .desc {
    padding: 0 10px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .SignaturePage .terms {
    min-height: 140px;
    max-height: 140px;
  }
  .SignaturePage .signatureCanvas,
  .SignaturePage .canvas-wrapper {
    height: 160px;
  }
}
@media (min-width: 920px) {
  .SignaturePage {
    min-height: 100vh;
  }
  .SignaturePage .sig-wrapper > * {
    margin: 10px 0;
  }
  .SignaturePage .title {
    padding-left: 0;
    margin-top: 10px;
    line-height: 35px;
    font-size: 24px;
  }
  .SignaturePage .desc {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .SignaturePage .terms {
    min-height: 180px;
    max-height: 180px;
  }
  .SignaturePage .signatureCanvas,
  .SignaturePage .canvas-wrapper {
    height: 200px;
  }
}
