$searchtextcolor: #a5a7a6;
$white: #fff;

#root .__mm,
#root .__zg {
  font-family: Myanmar3 !important;
}

@media (max-width: 700px) {
  body {
    background-color: #f4f3f4;
  }

  .homeInApp {
    #gorgias-web-messenger-container {
      display: none;
    }
  }

  .inApp {
    .bgHome {
      width: 100% !important;
      max-width: 600px !important;
      min-width: 100% !important;
      background-image: none !important;
      height: auto !important;
      min-height: 100vh !important;
      .logo {
        width: 150px !important;
        padding-top: 9px !important;
        margin-left: 10px;
      }
      // .menuIcon {
      //    display: none !important;
      // }
    }

    .bgWave {
      background-color: #1692c5 !important;
      background-image: none !important;
    }
    .bgKbz {
      background-color: #0b55a8 !important;
      background-image: none !important;
    }
    .bgOnePay {
      background-color: #45acab !important;
      background-image: none !important;
    }

    .btnBlueSearch .btnWave {
      background-color: #fee337 !important;
      color: #000 !important;
    }

    .btnBlueSearch .btnKbz {
      background-color: rgba(0, 0, 0, 0.5) !important;
      color: #fff !important;
    }
    .btnBlueSearch .btnOnePay {
      background-color: #2e9d68 !important;
      color: #fff !important;
    }
  }
}

body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  // background-color: #fafafa;
  // overflow-y: scroll;
  background-color: rgb(39, 49, 66);
}
.bgHome {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4bUOAwLxpJOyvdsFAfXcxV/27af4afb4eb673ee272fd6f3fcdfb350/background-desktop-1080-min.jpg");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background: url("../images/background/four/background-desktop_01.jpg") left
  //     top no-repeat,
  //   url("../images/background/four/background-desktop_02.jpg") right top
  //     no-repeat,
  //   url("../images/background/four/background-desktop_03.jpg") left bottom
  //     no-repeat,
  //   url("../images/background/four/background-desktop_04.jpg") right bottom
  //     no-repeat;
  // background-size: 50.01% 50.01%;
  background-color: #273142;
  background-origin: border-box;
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
}
.logo {
  width: 180px;
  padding-top: 10px;
}
.MuiContainer-root {
  padding: 0px !important;
}
.menu {
  float: left;
  font-family: "Roboto", Myanmar3;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding-right: 27px !important;
  padding-left: 0px !important;
  float: left;
  a {
    font-family: "Poppins", Myanmar3;
    font-weight: 400;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #fff !important;
    text-decoration: none !important;
    width: 100%;
    span,
    img {
      float: left;
      font-size: 18px !important;
    }
  }
}
.menu:hover {
  background-color: transparent !important;
}

.menuList {
  padding: 0px 12px 0px !important;
}

.bgHome,
#bgBalloon,
#bgBus {
  .menuList li a,
  .menuList li a > span {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .menuList li:last-child span {
    padding-bottom: 1px;
  }
  .menuList li:last-child span.myanmarFont {
    padding-bottom: unset;
  }
}

.homeCenterInfo {
  font-family: "Poppins", Myanmar3;
  font-weight: 400;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: 20vh !important;
  position: relative;
  padding-bottom: 30px;
  .divDiscover {
    margin: auto;
    max-width: 538px;
    text-align: right;
  }
}

.myanmar {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 700;
  font-size: 110px;
  color: #fff;
  line-height: 60px;
  position: relative;
}
.search {
  background-color: #fff;
  height: 74px;
  border-radius: 5px;
  // margin-top: -30px !important;
  padding: 0px 0px 0px 30px;
}
.nopadding {
  padding: 0px !important;
}

.pull-right {
  float: right !important;
}
.searchIcon {
  width: 26px;
  height: 26px;
  margin-top: 24px;
  padding-right: 10px;
  float: left;
}
.btnSearch {
  max-width: 120px !important;
  max-height: 74px !important;
  button {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    font-size: 18px !important;
    text-transform: capitalize !important;
    height: 100%;
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    box-shadow: none !important;
    width: 120px !important;
    margin-right: -1px;
    min-height: 73.5px;
  }
  .btnActive {
    background-color: #01b7f2 !important;
  }
}
#fl-home-btn-search span {
  pointer-events: none;
}
.MuiFormLabel-root.Mui-focused,
.selectedAiport {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.MuiFormLabel-root {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.container {
  width: 1200px;
  max-width: 1200px;
  margin: auto;
}

.homeContainer {
  // width: 1200px;
  max-width: 1200px;
  // min-width: 1200px;
  padding: 0px 50px;
  height: 100vh;
  margin: auto;
  .DateInput {
    width: 100% !important;
  }
  .DateInput_input {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 95% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .flightSearch .DateInput_input {
    font-size: 18px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 90% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .DateInput__disabled {
    width: 0px !important;
  }
  .DateInput_1 {
    #start_date_id {
      position: inherit;
      padding: 7px 13px 7px 7px;
      min-width: 200px;
    }
    #end_date_id {
      padding: 7px 22px 7px 0px;
      margin: auto;
      margin-top: 5px;
      width: 0px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #start_date_id {
      padding: 10px 2px 7px 7px !important;
      min-width: 130px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #return_start_date_id {
      padding: 10px 2px 7px 7px !important;
    }
  }
  .selected #end_date_id {
    margin-top: -10px !important;
  }
  .selected .DateRangePickerInput {
    padding-top: 0px !important;
  }

  .DateInput_input::placeholder {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: $searchtextcolor !important;
    padding: 0px 11px 9px;
  }

  .DateRangePickerInput,
  .DateInput_input__focused {
    border: none !important;
    width: 100%;
    padding-top: 13px;
  }
  .DateInput {
    line-height: 40px !important;
    // width: 50% !important;
    float: left;
  }
}
.DateRangePicker {
  width: 80%;
  height: 30px;
}
.DateInput_fang {
  bottom: 70px !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  background: #00aeef !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected_span {
  background: #e5f7fb !important;
  border: none !important;
  color: #000 !important;
}
.CalendarDay__default {
  border: none !important;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff !important;
  color: #cacccd !important;
  border: none !important;
}
.DateRangePickerInput__disabled,
.DateInput_input__disabled,
.DateInput__disabled {
  background: #fff !important;
}
.myanmarCitizen,
.passengerType {
  width: 30%;
  float: left;
  margin-top: -33.5px;
  position: initial;
  margin-left: -30px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  span {
    color: #fff;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }
  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }
  .MuiSvgIcon-root {
    font-size: 1rem !important;
    color: #fff !important;
  }
}
#tripType {
  margin-left: 0px;
  min-width: 130px;
  cursor: pointer;
}
.cities-popup.return-popup {
  left: 0% !important;
  padding: 5px !important;
  .MuiGrid-item {
    padding: 0px !important;
  }
  span {
    padding: 10px !important;
    display: block;
  }
}
div[id^="__lpform_"] {
  display: none;
}
@media (min-width: 800px) {
  .myanmarCitizen {
    max-width: 250px !important;
  }
}
.searchBox {
  #fromAirport,
  #toAirport {
    height: 1.3875em !important;
  }
}
.passengerType {
  //margin-left: 0px !important;
  padding: 5px 10px !important;
  width: auto !important;
  height: 24px;
}
.MuiInputBase-input {
  padding: 0px !important;
}
.Mui-focused,
.MuiFormLabel-filled {
  line-height: 36px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  .MuiSvgIcon-root {
    color: #01b7f2 !important;
  }
}
.MuiSvgIcon-root,
.MuiFormControlLabel-label {
  color: #fff !important;
}
.swapDestination {
  color: #707070 !important;
}
.focusedCalendar {
  margin-top: -15px;
}
.DateRangePickerInput_calendarIcon {
  padding: 0px !important;
  margin: auto !important;
}
.bgHomeService {
  background-color: #ffffff;
  .homeContainer {
    height: auto !important;
    max-width: unset;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    .hire:first-child {
      order: 2;
      margin-top: 100px !important;
      text-align: center;
      background-image: url("../images/curve.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      img {
        margin-top: 40px;
      }
    }
    .hire:nth-child(2) {
      order: 3;
      background: #f7f7f7;
      margin-top: 0 !important;
      padding-bottom: 80px;
    }
    .hire:nth-child(3) {
      //max-width: 1200px;
      margin: 0 auto;
      padding: 0 50px;
    }
  }
}
.hire {
  margin-top: 15px !important;
  .ourServiceTitle {
    font-family: "Poppins", Myanmar3;
    font-weight: 500;
    font-weight: 500;
    text-align: center;
    font-size: 30px;
  }
  .ourServiceTitle {
    margin-top: 30px !important;
    color: #000 !important;
  }
  & > span {
    font-family: "Poppins", Myanmar3 !important;
    font-weight: 500;
    display: block;
    font-size: 30px;
    font-weight: 500 !important;
    text-align: center;
  }
  span {
    // font-size: 1.1333em;
    line-height: 1.25em;
    font-weight: 600;
    color: #000 !important;
  }
}
@media (min-width: 600px) {
  .hire.MuiGrid-grid-sm-6 {
    max-width: unset;
    flex-basis: 100%;
  }
}
.car-ads-box {
  background: #fff none repeat scroll 0 0;
  text-align: left;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  margin-top: 10px;
  a {
    text-decoration: none !important;
  }
}
.car-ads-box > div {
  width: 100%;
  height: 253px;
  max-width: 290px;
}
.car-ads-logo {
  text-align: center;
  img {
    width: 100px;
    height: 119px;
  }
}
.airport-transfer-ads {
  border-right: 1px solid #ccc;
  height: 205px;
  overflow: visible;
  // animation-duration: 1s;
  // border-left: 7px solid #01b7f2;
  // border-radius: 5px;
  // padding: 10px;
  // background-color: #fff;
  // min-height: 50px;
  // border-bottom: 1px solid #01b7f2 !important;
  // border-top: 1px solid #01b7f2 !important;
  // border-right: 1px solid #01b7f2 !important;
}
.multi-city-ads {
  border-right: 1px solid #ccc;
  height: 205px;
  overflow: visible;
  // animation-duration: 1s;
  // border-left: 7px solid #f7696b;
  // border-radius: 5px;
  // padding: 10px;
  // background-color: #fff;
  // border-bottom: 1px solid #f7696b !important;
  // border-top: 1px solid #f7696b !important;
  // border-right: 1px solid #f7696b !important;
}
.city-tour-ads {
  border-right: 1px solid #ccc;
  height: 205px;
  overflow: visible;
  // animation-duration: 1s;
  // border-left: 7px solid #fbb040;
  // border-radius: 5px;
  // padding: 10px;
  // background-color: #fff;
  // border-bottom: 1px solid #fbb040 !important;
  // border-top: 1px solid #fbb040 !important;
  // border-right: 1px solid #fbb040 !important;
}
.city-to-city-ads {
  height: 205px;
  overflow: visible;
  // animation-duration: 1s;
  // border-left: 7px solid #454c66;
  // border-radius: 5px;
  // padding: 10px;
  // background-color: #fff;
  // border-bottom: 1px solid #454c66 !important;
  // border-top: 1px solid #454c66 !important;
  // border-right: 1px solid #454c66 !important;
}
.car-ads-url {
  text-decoration: none !important;
}
.adsDesc {
  font-size: 14px !important;
  color: #838381 !important;
  font-weight: 400 !important;
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 85%;
  line-height: 1.4em !important;
}
.adsTitle {
  font-size: 16px !important;
  color: #838381 !important;
  display: block;
  text-align: center;
  margin: 15px 0;
}
.book-now-button {
  width: 200px !important;
  height: 50px !important;
  display: block !important;
  margin: 40px auto 0 !important;
  border: solid 2px #f7696b !important;
  border-radius: 25px !important;

  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: bold;
    color: #f7696b !important;
    text-transform: capitalize;
  }
}
.website-ads {
  width: 130%;
}
@media (min-width: 1300px) {
  .airport-popup {
    margin-left: 20% !important;
  }
}
@media (max-width: 780px) {
  .passengerType {
    display: none !important;
  }
  .footer-socialDiv {
    width: 170px;
    text-align: center;
    margin: auto;
    display: block !important;
  }
  .footer-app .grid-footer:first-child {
    width: 170px;
    margin: auto;
    display: block;
    text-align: center;
  }
  .appDownload {
    text-align: center;
  }
  .website-ads {
    height: 170px;
    width: 300px !important;
  }
  .footer-social-media {
    margin: auto !important;
  }
  .MuiCard-root.confirmationBox.covidAlertBox {
    top: 50% !important;
    max-width: 100vw !important;
    left: 0% !important;
  }
}
@media (min-width: 781px) {
  .myanmarCitizen,
  .passengerType {
    width: unset;
  }
  .searchBox {
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 800px) {
  .swapDestination {
    margin-top: 25px !important;
    margin-left: -40px !important;
    // position: absolute;
    float: left;
    cursor: pointer;
  }
  .flightSearch .swapDestination {
    margin-top: 25px !important;
    margin-left: -19px !important;
    // position: absolute;
    float: left;
    cursor: pointer;
  }
  .myanmarCitizen,
  .passengerType {
    width: unset !important;
  }
  .search {
    .searchRadioGroup {
      display: flex;
      justify-content: flex-start;
      position: relative;
      bottom: 1px;
    }
  }
}

.airport-popup,
.cities-popup {
  //width: 600px;
  width: 300px;
  margin-left: 12%;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
  .MuiGrid-item {
    padding: 7px 12px !important;
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .MuiGrid-item:hover {
    color: #00aeef !important;
    cursor: pointer;
  }
}

.top.airport-popup:after,
.top.cities-popup:after {
  content: "";
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
}
.top.cities-popup.return-popup:after {
  margin-top: -7px !important;
}
.bottom.airport-popup:after,
.bottom.cities-popup:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  top: -3px;
  left: 15%;
  position: absolute;
}
.cities-popup {
  width: 200px !important;
  left: -30% !important;
  margin-left: 0% !important;
}

.flightType {
  background-color: #01b7f2;
  color: #fff;
  border-radius: 15px;
  padding: 7px 11px;
  width: 60px;
  float: left;
  font-size: 14px;
  line-height: 15px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
}
.MuiInputBase-root {
  color: #231f20 !important;
}
.selectedAirport {
  color: #000000 !important;
  line-height: 30px;
  padding-left: 10px;
  line-height: 30px;
  padding-left: 10px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  font-size: 16px;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}
.divFeaturedIn {
  background-color: #f7f7f7 !important;
  padding-top: 50px !important;
  padding-bottom: 40px;
  background-color: #f7f7f7;
  .container {
    width: 100%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .col {
    padding: 0 5px;
    margin: 0 auto;
  }
}
.featuredInContainer {
  margin-top: 25px;
}
.divAirlinePartner {
  //margin-top: 75px !important;
  background-color: #f7f7f7;
  //background-image: url("../images/airline-bg.jpg");
}
.titleSlider {
  // font-weight: normal;
  color: #00b7f0;
  // font-size: 1.267em !important;
  // line-height: 50px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: bold;
  font-size: 16px;
  display: block;
}
.airlinePartner,
.airlineFeatured {
  display: block;
  text-align: center;
  font-size: 30px !important;
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 500 !important;
  color: #000;
}
.ourServiceTitle {
  font-size: 1.1333em;
  line-height: 1.25em;
  font-weight: 600;
  color: #2d3e52 !important;
}
.footer {
  color: white;
  background-color: #2e2e2e;
  min-height: 50px;
  & > .container {
    margin: 0;
    width: 100%;
    max-width: unset;
  }
  .container .MuiGrid-container {
    max-width: 1200px;
    width: 100%;
    margin: 40px auto 0px;
    justify-content: space-around;
  }
  .titleSlider {
    margin-bottom: 20px;
  }
}
.footer-gridbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid white;
  font-family: "Roboto", Myanmar3;
  font-size: 16px;
  font-weight: bold;
}
.grid-footer a {
  &:hover {
    color: #00b7f1;
  }
}
.grid-footer:first-child,
.grid-footer:nth-child(2),
.grid-footer:nth-child(3),
.grid-footer:nth-child(4) {
  margin-bottom: 50px;
}
.grid-footer:last-child {
  justify-content: center;
  .titleSlider {
    text-align: center;
  }
}
.grid-footer:nth-child(4) {
  & > div > img {
    width: 180px;
    padding: 15px 0 15px;
  }
  & > div > div {
    display: flex;
    div:first-child {
      margin-right: 15px;
    }
  }
  & div > div span {
    display: block;
    &:first-child {
      font-size: 14px;
      padding-bottom: 5px;
    }
    &:not(:first-child) {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.grid-footer:last-child {
  // > div {
  //   width: 170px;
  // }
  display: flex;
  width: 230px;
}
.grid-footer:nth-child(3) {
  display: flex;
  width: 230px;
}
.footer-bar {
  display: flex;
  align-items: center;
  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1023px) {
}
//Medium break points
@media (max-width: 960px) {
  .divAirlinePartner .bgWhite {
    flex-wrap: wrap;
    justify-content: center;
    div {
      width: 30%;
    }
  }
  .footer .container .MuiGrid-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 959px) {
  .grid-footer:nth-child(4) > div > img {
    padding-top: 0 !important;
  }
  .grid-footer:last-child {
    justify-content: flex-start;
    .titleSlider {
      text-align: left;
    }
  }
}

.footer-textfield input {
  width: 230px;
  height: 45px;
  border: 1px solid #2e2e2e;
  border-radius: 25px;
  background: white;
  outline: none;
  padding-left: 15px !important;
  margin-right: 15px;
}
.footer-subscribe {
  height: 45px;
  width: 120px;
  vertical-align: middle;
  border-radius: 25px !important;
  border: 1px solid white !important;
  margin-top: 5px;
  span {
    color: white;
    text-transform: capitalize;
  }
}
.footer-bottom {
  height: 200px;
  display: inline-block;
  overflow: hidden;
  td {
    font-size: 12px;
    font-family: "Roboto", Myanmar3 !important;
    a {
      color: #fff !important;
    }
    a:hover {
      color: #94dbeb !important;
    }
  }
  .row {
    display: flex;
  }
  .col {
    padding-right: 10px;
    padding-bottom: 10px;
    img {
      width: 70px;
    }
  }
}
.footer-social-media {
  display: flex;
  justify-content: space-around;
  max-width: 150px;
  margin: 0;
  img[src*="linkedin"] {
    height: 44px;
    width: 44px;
    margin-top: -1px;
    margin-left: -2px;
  }
}
.bgWhite {
  background-color: $white;
}

.divAirlinePartner {
  .container {
    width: 100%;
  }
  .bgWhite {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-bottom: 20px;
    margin-top: 5px;
    background-color: #f7f7f7;
  }
}
.maillist {
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-style: italic;
}
.contact-details {
  padding-bottom: 20px;
  padding-top: 10px;
}
.departfrom {
  width: calc(33.3333% - 39.99996px);
  max-width: calc(33.3333% - 39.99996px) !important;
  min-width: calc(33.3333% - 39.99996px);

  .MuiTextField-root {
    margin-top: 20px !important;
    width: 70%;
  }
  .MuiInputBase-root {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Roboto", Myanmar3 !important;
    margin-top: 15px !important;
    label {
      margin-top: 5px;
    }
  }
  .MuiInputLable-animated {
    margin-top: -5px !important;
  }
}
@media (min-width: 780px) {
  .flightSearch .departfrom {
    // max-width: calc(33.34% - 40px) !important;
    // min-width: calc(33.34% - 40px) !important;
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(33.36% - 40px) !important;
    max-height: 73px !important;
  }
  .flightSearch .btnSearch {
    margin-top: -1px !important;
  }
  .flightSearch.hasReturn .departfrom {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }
  .flightSearch.hasReturn .btnSearch {
    margin-top: -0.8px !important;
  }
}
.returnTrip {
  padding-left: 10px !important;
}
.MuiFormControl-root {
  label {
    margin-top: -12px;
  }
}
.MuiFormControl-root {
  .MuiInputLabel-shrink {
    margin-top: -5px !important;
    transform: translate(0, -5.5px) scale(0.85) !important;
  }
}
.dividerAiport {
  height: 10px;
  padding: 10px 0px;
}
.departureDate {
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.busOperatorLogo {
  .imgContainer {
    display: inline-block;
    width: 184px !important;
    max-width: 184px !important;
    img {
      max-width: 100%;
      width: 100% !important;
      margin: -15px 0px;
    }
  }
}
.block {
  display: block !important;
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  opacity: 1;
  transform: translate(0, 1.5px) scale(1);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-top: 8px;
}
.departFlight {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 400;
  float: left;
  margin-left: 8px;
  width: 37%;
  min-width: 100px;
  margin-top: 4px;
}
.returnFlight {
  float: right;
  margin-left: 50px;
  width: 45%;
}
.hide {
  display: none !important;
}
.mmBusLogo img,
.mmBusLogo span {
  font-size: 12px !important;
  float: right !important;
}
.mmBusLogo img {
  width: 70px !important;
}
.mmBusLogo span {
  color: #fff !important;
  line-height: 70px !important;
}
@media (min-width: 700px) {
  .mobile-from-to {
    display: none !important;
  }
}
.DateInput_input__focused::placeholder {
  opacity: 0 !important;
}
.alice-carousel__stage-item {
  opacity: 0.5;
  width: 200px;
}
.alice-carousel__stage-item:hover {
  opacity: 1 !important;
}
.airlineImg {
  width: 184px;
  height: 61px;
  padding: 20px 0;
}
.pull-left {
  float: left;
}
.clickAwayDiv {
  width: 100%;
  float: left;
  height: 100%;
}
.flightSearch .clickAwayDiv {
  width: 87% !important;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  left: 25px;
  border-right: 9px solid #4b515d;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  right: 25px;
  border-left: 9px solid #4b515d;
}
.DayPicker_weekHeader {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  font-size: 14px !important;
}
.DateRangePicker_picker {
  border-radius: 5px;
  left: -180px !important;
  bottom: 68px !important;
  z-index: 10 !important;
}
.checkReturnDate {
  margin-top: 13px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}
.selectedCheckReturn {
  margin-top: 23px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}
.btnBlueSearch {
  display: none !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pull-left {
  float: left;
}
.homeMenu,
.chooseReturnDate {
  opacity: 0 !important;
}
.datepicker {
  margin: auto;
  max-height: 74px;
  position: relative;
}
.textRight {
  text-align: right !important;
}
.dateRange {
  width: -webkit-calc(0.333333 * (100% - 120px));
  width: -moz-calc(0.333333 * (100% - 120px));
  width: calc(0.333333 * (100% - 120px));
  max-width: calc(0.333333 * (100% - 120px)) !important;
  min-width: calc(0.333333 * (100% - 120px));
  //margin: auto 0px !important;
}

.DateRangePickerInput_arrow {
  display: none !important;
}
.calendarIcon {
  // position: absolute;
  z-index: 30;
  line-height: 50px;
  margin: 24px 0px;
  width: 26px;
  height: 26px;
  float: left;
}
.flightSearch .calendarIcon {
  margin: 22px 0px;
}
.selected-calendar {
  margin-top: 24px !important;
}
.dividerContainer {
  margin-top: 5px !important;
}
.loadingWrapper {
  margin: 20px auto;
  width: 889px !important;
  text-align: left;
  background-color: #fff;
  padding: 20px;
}
.btnDisable {
  background-color: #01b7f2 !important;
  pointer-events: none;
}

.noborder {
  border: none !important;
}
.airport-popup {
  .MuiGrid-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(10, auto);
    height: auto !important;
    .MuiGrid-grid-xs-4 {
      max-width: 100% !important;
    }
  }
}

.MuiGrid-item.page404 {
  display: block;
  background-color: #273142;
  position: fixed !important;
  text-align: center;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  .title {
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 80px;
    color: #fff !important;
    display: inline-block;
    margin-top: 20vh;
  }
  .warning {
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    font-size: 30px;
    color: #919191;
  }
}

.pageHeaderLogo {
  width: 150px;
  display: block;
}
.CalendarDay__default.CalendarDay__today {
  color: #0041ff !important;
  border: 2px solid #a7a7a7 !important;
  border-radius: 3px;
}
.MuiMenuItem-root {
  overflow: inherit !important;
  .searchBox {
    .MuiInputBase-input {
      width: 90% !important;
    }
  }
}
.languageDiv {
  box-shadow: 0 5px 11px #ccc;
  border: 1px solid hsla(0, 0%, 50.2%, 0.08);
  text-align: center !important;
  padding: 0rem 0.5rem !important;
}
.languageButtonEng {
  text-align: center;
}
.languageButton {
  text-align: center;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.languageButtonMya {
  margin-right: 1rem !important;
}
.disableButton {
  opacity: 0.3 !important;
  cursor: default !important;
}
.myanmarFont,
span,
label,
.MuiFormLabel-root,
.departFlight {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}
.homeAgeSelect {
  width: 90%;
  float: left;
  margin: 5px 0px;
}
.pasengerTypeCard {
  max-width: 260px;
  padding: 20px;
  left: 25%;
  z-index: 1000;
  .ageSelect {
    width: 90% !important;
  }
}
.homeAgeSelect {
  width: 90%;
  float: left;
  margin: 5px 0px;
}
.pasengerTypeCard {
  max-width: 260px;
  padding: 20px;
  left: 25%;
  z-index: 1000;
  .ageSelect {
    width: 90% !important;
  }
}
.homeChooseAge {
  float: right;
  margin: 10px 0 10px 20px;
  .react-numeric-input {
    width: 100%;
    padding-left: 2px;
    height: 30px;
  }

  .numeric-counter {
    padding: 0px !important;
    height: 30px;
    width: 106px;
    border: 1px solid #c7c7c7 !important;
  }

  b {
    background-color: #fff !important;
    border: none !important;
    border-right: 1px solid #c7c7c7 !important;
    border-left: 1px solid #c7c7c7 !important;
    width: 4.3ex !important;
    cursor: pointer !important;
  }
}
.passengerTooltip {
  z-index: 1000 !important;
}

@media (min-width: 800px) and (max-width: 1000px) {
  .myanmarCitizen {
    width: 250px;
  }
}
.selected--flag--option,
.arrow-down {
  color: #fff !important;
}
.flag-select {
  margin-top: 5px;
}
.homeFlag {
  float: right !important;
}
.homeFlag > .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}
#homeFlag .flag-select {
  float: right !important;
  margin-top: 3px !important;
}
#homeFlag .flag-select .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}
#homeFlag .flag-select .arrow-down {
  color: #fff !important;
}
.__react_component_tooltip {
  z-index: 99999 !important;
}
//TABLE
@media (min-width: 769px) and (max-width: 1023px) {
  .homeContainer {
    // .MuiGrid-item:first-child {
    //   #homeFlag .flag-select {
    //     display: none;
    //   }
    //   button.menuIcon.nopadding {
    //     display: none;
    //   }
    // }
    .menuList {
      position: absolute;
      right: 0;
    }
  }
}
@media (min-width: 800px) {
  .deptAirportImgDiv {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/62c55efdfc0ea1f529bc6d6efbf488a9/icon-sprite-min.png");
    background-position: 1px -91px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    margin-right: 15px;
  }
  .arrivAirportImgDiv {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/62c55efdfc0ea1f529bc6d6efbf488a9/icon-sprite-min.png");
    background-position: 1px -117px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    margin-right: 15px;
  }
  .flightSearch .arrivAirportImgDiv {
    margin-left: 5px !important;
  }
  .calendarImgDiv {
    background-image: url("../images/sprite-images.png");
    background-position: 1px -142px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    // margin-right: 15px;
  }
  .down-arrow-img {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/6f3637e8be7652b1a57b94218e0a9e7c/icon-sprite-min.png");
    background-position: 143px -27px;
    background-size: 220px;
    width: 24px;
    height: 24px;
    float: right;
    margin-left: 5px;
  }
  .allday-img {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/6f3637e8be7652b1a57b94218e0a9e7c/icon-sprite-min.png");
    background-position: -6px -77px;
    background-size: 187px;
    width: 58px;
    height: 49px;
    float: right;
    margin-right: 5px !important;
  }
}
.MuiDialog-paperWidthSm {
  min-width: 70% !important;
  position: relative !important;
}
.MuiDialogTitle-root {
  padding: 5px 12px !important;
}
.Component-closeButton-* {
  top: -8px !important;
  right: -5px !important;
  position: relative !important;
}
.offlineCloseIcon {
  top: -3px !important;
}

/*feature image sprite*/
.feature-img-irrawady-burma {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: 12px -10px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}
.feature-img-goaway {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -239px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature-img-honeycamber {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -333px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature-img-ncra {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -397px -93px;
  background-size: 1000px;
  width: 135px;
  height: 65px;
  float: left;
}
.feature-img-mizzima-burma {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: 10px -93px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}
.feature-img-malay-mail-online {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -568px -26px;
  background-size: 1000px;
  width: 115px;
  height: 65px;
  float: left;
}
.feature-img-sd-asia {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -563px -96px;
  background-size: 1000px;
  width: 108px;
  height: 65px;
  float: left;
}
.feature-img-today {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -853px -96px;
  background-size: 1000px;
  width: 81px;
  height: 65px;
  float: left;
}
.feature-img-mizzima-eng {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -816px -16px;
  background-size: 976px;
  width: 127px;
  height: 65px;
  float: left;
}
.feature-img-mingalapar {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -698px -25px;
  background-size: 1000px;
  width: 119px;
  height: 65px;
  float: left;
}

.feature-img-myan-biz-today {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -254px -86px;
  background-size: 960px;
  width: 103px;
  height: 65px;
  float: left;
}
.feature-img-coconut-bkk {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -402px -163px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}
.feature-img-coconut-ygn {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -135px -158px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}
.feature-img-yangon-life {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -259px -158px;
  background-size: 1000px;
  width: 137px;
  height: 65px;
  float: left;
}
.feature-img-irrawaddy {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -450px -17px;
  background-size: 1000px;
  width: 110px;
  height: 65px;
  float: left;
}
.feature-img-travel-leisure {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -10px -155px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature-img-myanmar-times {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -126px -89px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}
.feature-img-new-light {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -126px -12px;
  background-size: 1000px;
  width: 122px;
  height: 65px;
  float: left;
}
.feature-img-tech-in-asia {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -684px -98px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}
/*feature image sprite*/

/*airline image sprite*/
.airline-gma {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -200px -67px;
  background-size: 410px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-airkbz {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -18px -144px;
  background-size: 430px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-yangon-airway {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -4px 10px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-mna {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: 182px 12px;
  background-size: 390px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-myp {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -16px -280px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-air-thanlwin {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -213px -134px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}
/*airline image sprite*/

/*payment image sprite*/
.payment-img-visa {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -9px -11px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-mobile-banking {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -105px -11px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-mpu {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -202px -11px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-123 {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");

  background-position: -9px -72px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-kbz-quickpay {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -106px -72px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-cod {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -202px -72px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-wave-pay {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -9px -136px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-kbz-pay {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -105px -136px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
.payment-img-one-pay {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/7irSE8vDrvlgVJGLz9LeD8/03397b518651052c0286d5fe37852a6c/payment_logo.png");
  background-position: -202px -136px;
  background-size: 280px;
  width: 70px;
  height: 50px;
  float: left;
}
/*payment image sprite*/

/*our service image sprite*/
.car-ads-airport {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/3BPRjvSLXh5FNEFpXHNdVU/b929485c9a885477146e0a1e63865958/our-service-img-sprites.png");
  background-position: 248px 133px;
  background-size: 400px;
  width: 100px;
  height: 120px;
  margin: auto;
}
.car-ads-city {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/3BPRjvSLXh5FNEFpXHNdVU/b929485c9a885477146e0a1e63865958/our-service-img-sprites.png");
  background-position: 518px -15px;
  background-size: 400px;
  width: 100px;
  height: 120px;
  margin: auto;
}
.car-ads-mini {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/52lW9xQk7hnNfsWGuGO3HQ/a63eea71d1db9d52ff316985c25a64b5/our_services_new.png");
  background-position: -185px 5px;
  background-size: 700px;
  width: 100px;
  height: 120px;
  margin: auto;
}
.car-ads-land {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/52lW9xQk7hnNfsWGuGO3HQ/a63eea71d1db9d52ff316985c25a64b5/our_services_new.png");
  background-position: -281px 5px;
  background-size: 700px;
  width: 100px;
  height: 120px;
  margin: auto;
}
.car-ads-balloon {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/3BPRjvSLXh5FNEFpXHNdVU/b929485c9a885477146e0a1e63865958/our-service-img-sprites.png");
  background-position: 385px -15px;
  background-size: 400px;
  width: 100px;
  height: 120px;
  margin: auto;
}
.car-ads-bus {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/3BPRjvSLXh5FNEFpXHNdVU/b929485c9a885477146e0a1e63865958/our-service-img-sprites.png");
  background-position: 255px 0px;
  background-size: 400px;
  width: 115px;
  height: 120px;
  margin: auto;
}
.car-ads-flight {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/3BPRjvSLXh5FNEFpXHNdVU/b929485c9a885477146e0a1e63865958/our-service-img-sprites.png");
  background-position: -10px 134px;
  background-size: 400px;
  width: 115px;
  height: 120px;
  margin: auto;
}
//tablet and desktoo
@media (min-width: 781px) {
  .bgHome #DepartureGrid {
    border-right: 1px solid #e4e4e4;
    // margin-right: 21px;
    // width: calc(24% - (39.99996px + 10px));
    // min-width: calc(24% - (39.99996px + 10px)) !important;
    width: 24% !important;
  }
  .bgHome #swapDestinationIcon {
    pointer-events: none;
    border: 1px solid #e4e4e4;
    border-radius: 50px;
    padding: 5px;
    margin-top: 19px !important;
    background-color: #fff;
  }
  .bgHome #ArrivalGrid {
    // width: calc(24% - (40.99996px + 10px));
    // min-width: calc(24% - (40.99996px + 10px));
    width: 24% !important;
  }
  .bgHome #DateRangeDiv {
    border-left: 1px solid #e4e4e4;
    // border-right: 1px solid #e4e4e4;
    // padding-left: 20px;
    // width: calc(24% - (39.99996px + 11px));
    // min-width: calc(24% - (39.99996px + 11px));
    width: 24% !important;
  }
  .bgHome #tripTypeDiv {
    border-left: 1px solid #e4e4e4;
    // padding-left: 20px;
    // width: calc(24% - (39.99996px + 11px));
    // min-width: calc(24% - (39.99996px + 11px));
    // margin-right: 4px !important;
    width: 24% !important;
  }
}
@media (max-width: 780px) {
  .mdatepicker .SingleDatePicker_picker {
    position: fixed !important;
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    border-radius: 3px;
  }
  #bg2 {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .footer-bottom {
    height: auto !important;
    td {
      height: 48px;
      font-size: 16px;
    }
  }
  .footerPhoneNo {
    height: 48px;
    line-height: 45px;
  }
  .DateInput_fang {
    display: none !important;
  }
  #ArrivalGrid,
  #DepartureGrid {
    width: 100%;
  }
}
@media (min-width: 781px) {
  .CalendarDay__default {
    border: none !important;
  }
  .sortingBox .MuiInput-root.mm-pull-right {
    width: 175px !important;
  }
}

.tripTypeContainer.MuiGrid-item {
  padding: 0px 0px !important;
}

.tripTypeContainer .tripTypelabel {
  padding: 7px 12px;
  display: block;
}

.tripTypeLableSelected {
  background: #e8e8e8 !important;
  border-radius: 4px !important;
}
.tripTypeLableSelected span {
  color: #000000 !important;
  font-weight: 700 !important;
}
.tripType {
  margin-right: 5px !important;
}
.tripTypePopupTooltip div {
  width: 140px !important;
}
.tripTypePopupTooltip div::after {
  margin-left: 22px !important;
}
.returnText {
  font-family: "Poppins";
  font-weight: 300 !important;
  color: #231f20 !important;
  font-size: 28px !important;
  line-height: 32px !important;
}
.stayText {
  color: #8d8d8d !important;
  font-size: 18px !important;
  line-height: 20px !important;
}
.tripTypeConfirmationBox .btnContinue span {
  text-transform: none !important;
}
.tripTypeConfirmationBox {
  width: auto !important;
  top: 7% !important;
}
.tripTypeConfirmationBox div {
  margin-bottom: 30px !important;
}
.tripTypeDiv
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 16px !important;
}
#DateRangeDiv .calendarImgDiv {
  margin-left: 10px !important;
}

.appDownload {
  display: block !important;
  img {
    width: 150px;
    margin-right: 20px;
  }
}
.footer-app {
  margin-top: -10px !important;
}
.ABTestingDesktopSwapIcon {
  transform: rotate(180deg) !important;
}

.ABTestingDesktopSwapIcon.MuiSvgIcon-root {
  color: rgb(0, 174, 239) !important;
}
@media (min-width: 765px) {
  #ABTestingEmphasiseSearchBox {
    width: 100% !important;
    background: rgb(0, 0, 0) !important;
    height: 150px !important;
    border-radius: 8px !important;
    margin-top: 5% !important;
  }

  #ABTestingEmphasiseSearchBox .search {
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    width: 93% !important;
    margin-top: 50px !important;
  }

  #ABTestingEmphasiseSearchBox .myanmarCitizen {
    margin-left: -40px !important;
  }
}

.ABTestingDesktopMenuIcon {
  width: 24px !important;
  margin-right: 5px !important;
  margin-top: 2px !important;
}
.ABTestingDesktopMenuBalloonIcon {
  margin-right: 0px !important;
  width: 20px !important;
}
#BackgroundContainer .ABTestingDesktopMenuLink {
  border-bottom: 2px solid #e4e4e4;
  cursor: default !important;
}
#BackgroundContainer .ABTestingDesktopMenuLink span {
  font-weight: 500 !important;
}
#BackgroundContainer a > span {
  font-weight: 400 !important;
}
.nationality-radio-btn {
  max-width: 74px !important;
}
.selectedDepartFlightNo {
  display: inline-block;
  width: 75%;
  font-size: 14px;
}
.privacyPolicy {
  font-family: "Roboto", Myanmar3 !important;
  margin: 20px auto !important;
  p {
    line-height: 22px;
  }
}
#homeFlag {
  margin-left: 90% !important;
}
.flightSearch,
.flightPopup {
  z-index: 10 !important;
}
.homeContainer .flightSearch .DateInput_input {
  font-size: 16px !important;
}
.contactUsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}
.supportTitle {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}
.contactUsGet {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.leaveMessage {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 20px;
}
.contactUsBox {
  padding-left: 50px;
  .fieldTitle {
    font-family: "Roboto" !important;
    font-size: 13px !important;
    font-weight: 500;
  }
  .errMessage {
    font-weight: 400;
    color: red;
    font-size: 12px;
    font-family: "Roboto" !important;
  }
}
.customerContainer .contactUsFieldBox {
  border: 1px solid #e6e7e8;
  border-radius: 4px;
  width: 100%;
  .MuiInputBase-input {
    height: 30px;
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}
.customerContainer .contactUsMessageBox {
  border: 1px solid #e6e7e8 !important;
  border-radius: 4px;
  width: 100%;
  .MuiInputBase-input {
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}
.btnSendMessage {
  background-color: #f7911e !important;
  border-radius: 4px;
  color: #fff !important;
  font-family: "Roboto", Myanmar3 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  height: 45px;
  width: 150px;
}
.btnMessageBox {
  text-align: center !important;
  margin: 20px 0px !important;
  .MuiCircularProgress-root {
    color: #fff !important;
    width: 30px !important;
    height: 30px !important;
  }
}
.contactUsAddressBox {
  margin-top: 30px !important;
}
.officeAddressBox {
  padding-bottom: 30px;
  font-family: "Roboto", Myanmar3 !important;
  div:nth-child(3),
  div:nth-child(5) {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  :nth-child(4) {
    padding-bottom: 20px;
  }
  span {
    font-size: 14px !important;
  }
}
.contactUsContainer {
  background-color: #fff !important;
  margin: 0px !important;
}
.visitUs {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.officeAddress {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0px;
  text-transform: capitalize !important;
  line-height: 20px;
  a {
    text-decoration: none;
    color: #000 !important;
    line-height: 30px;
  }
}
.officeAddress.emailAddress {
  text-transform: lowercase !important;
}
.officeAddressTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0px 10px 0px;
}
.contactUsImg {
  width: 20px;
  height: 20px;
  padding-left: 10px;
}
.supportImage {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}
.MuiSnackbar-root {
  left: 7% !important;
  .MuiSnackbarContent-root {
    background-color: #4caf50 !important;
  }
  .MuiSvgIcon-root {
    color: #fff !important;
  }
}
.searchErrMessage .contactUsFieldBox,
.searchErrMessage .contactUsMessageBox {
  border: none !important;
  .MuiInputBase-root {
    border: 1px solid #ff0000 !important;
    border-radius: 4px;
  }
}
.nopadding-bottom {
  padding-bottom: 0px !important;
}
.contactUsMenu a {
  color: #000 !important;
  padding: 19px;
  font-family: "Roboto", Myanmar3 !important;
  float: right;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.mmContactUsMenu a {
  padding: 12px !important;
}
@media (max-width: 768px) {
  .contactUsBox {
    padding-left: 0px !important;
  }
  .contactUsAddressBox {
    padding-left: 20px !important;
  }
  .aboutUsImgContainer {
    padding: 30px 0px !important;
    img {
      width: 100% !important;
    }
  }
}
.aboutUsContent {
  font-size: 14px !important;
}
.aboutUsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 24px;
  margin: 30px 0px;
}
.aboutUsProductsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 18px;
}
.aboutUsProducts {
  text-align: center;
  padding-bottom: 30px !important;
  padding-top: 10px !important;
}
.aboutUsProductsDiv {
  padding: 20px 30px;
  font-family: "Roboto", Myanmar3 !important;
  p {
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;
    // min-height: 110px !important;
    height: 150px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  img {
    width: 80px;
  }
  a {
    text-decoration: none !important;
  }
}
.aboutUsProductAds {
  max-width: 800px;
  margin: auto;
  div {
    margin: auto;
  }
}
.btnAboutUsBookNow {
  background-color: #f7911e !important;
  width: 220px;
  border-radius: 4px !important;
  color: #fff !important;
  height: 50px !important;
  border: none !important;
  text-transform: capitalize !important;
  span {
    font-weight: 600;
    font-family: "Roboto", Myanmar3 !important;
  }
}
.aboutUsImgContainer {
  padding: 60px 50px;
  img {
    width: 365px;
  }
}
.signatureContent {
  padding-top: 50px;
}

#mobileFlightDateRangeLableDiv {
  top: -40px !important;
  position: absolute !important;
  padding-left: 15px !important;
  background: #fff;
  min-height: 50px;
  width: 562px !important;
  padding-top: 10px !important;
  border-radius: 4px !important;
}
.dateLableDividerDiv {
  width: 96% !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  height: 1px !important;
  border: none !important;
}
.flightSearch .DayPicker_focusRegion {
  margin-top: 50px !important;
}
#mobileFlightDateRangeLableDiv .flightType {
  width: 68px !important;
  text-align: center !important;
}
.abtDesktopDateDiv .DayPicker_focusRegion {
  margin-top: 0 !important;
}

.CalendarDay__default.CalendarDay__today {
  color: #000000 !important;
  border: none !important;
  border-radius: 6px !important;
}
.CalendarDay__default.CalendarDay__today:hover {
  color: #ffffff !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  outline: none !important;
}
@media (max-width: 780px) {
  .DayPicker {
    min-width: 370px !important;
  }
  .abtMobileDateDiv .DayPicker_focusRegion {
    margin-top: 0 !important;
  }
  #mobileFlightDateRangeLableDiv {
    top: -230px !important;
    z-index: 100 !important;
    margin-left: -17px !important;
    width: 100% !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
}
.flightSearch .CalendarDay__today {
  background: #fff !important;
  color: #cacccd !important;
}
.mobileFlightDateRangeLableDiv {
  display: none !important;
}
.confirmationBox.covidAlertBox {
  padding: 50px 5px !important;
  top: 50% !important;
  left: 35vw !important;
  width: 100%;
  max-width: 30vw;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  overflow-y: auto;
}

.covidTitle {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", Myanmar3 !important;
  line-height: 30px;
}
.btnClose {
  float: right;
  color: #000 !important;
  font-size: 30px;
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}
.covidAlertBox {
  padding: 5px 0px !important;
  img {
    margin-top: 3px;
    width: 20px;
    float: left;
    /* position: absolute; */
    margin-right: 5px;
  }
  .confirmationBoxTitle {
    text-align: left !important;
    font-family: "Poppins", Myanmar3 !important;
    line-height: 22px;
    .informationDiv {
      font-size: 14px !important;
      font-weight: 400 !important;
      text-align: left !important;
      font-family: "Poppins", Myanmar3 !important;
      a {
        color: #000 !important;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
}
.bgWhiteBanner {
  background-color: #fff;
}
.cities-popup.sleep-popup {
  width: 400px !important;
  left: 22% !important;
}
.balloonSearchDiv.departfrom {
  line-height: 37px !important;
}
@media (max-width: 780px) {
  .flightSearch .balloonSearchDiv.departfrom.cityPicker {
    min-width: 100% !important;
  }
  .balloonSearchDiv.departfrom {
    line-height: 10px !important;
  }
}
.balloonSearchDiv.departfrom {
  line-height: 25px !important;
}

.spacex-disable {
  opacity: 0.7 !important;
}
.cities-popup .spacex-disable.MuiGrid-item:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.spacex-hometitle {
  margin: auto;
  max-width: 538px;
  text-align: center;
}
.spacex-homeCenterInfo {
  font-size: 40px;
  color: #fff;
  position: relative;
  text-align: center;
  margin-top: 50px !important;
  margin-bottom: 25px !important;
}
.spacex-noflyMsg {
  color: #fff;
  font-size: 25px;
  font-family: "Roboto" !important;
  font-weight: 700;
  line-height: 35px;
  margin: 0 auto;
  margin-top: 40px;
}
.spacex-search.MuiGrid-item {
  // margin-top: -20vh !important;
  margin: 0 auto;
}

.intercom-lightweight-app-launcher.intercom-launcher {
  opacity: 0;
  pointer-events: none;
}

.date-region {
  .date-inner {
    .SingleDatePicker {
      padding-left: 10px;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 10px 15px !important;
    }
    .SingleDatePicker_picker {
      top: 45px !important;
      left: 10px !important;
    }
    .DayPicker {
      width: auto !important;
      min-width: 0 !important;
    }
    .MuiInputBase-formControl {
      margin-left: 10px;
    }
    .MuiCard-root {
      left: 10px;
    }
  }
}
