.SpacexFooter {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 3vw 4vw;
  background-color: #273142;
  box-sizing: border-box;
  z-index: 3;
}
.SpacexFooter > * {
  text-align: center;
  color: #abaeaf;
}

.SpacexFooter a {
  transition: color 250ms ease-out;
  color: #abaeaf;
}
.SpacexFooter a:hover {
  color: #2cafe8;
}
