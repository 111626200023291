.SpacexNavbar {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 20px 4vw 5px 4vw;
  background-color: #273142;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  z-index: 3;
  position: relative;
}
.SpacexNavbar > * {
  margin: 10px 0 !important;
}
.SpacexNavbar h1 {
  /* text-align: right;
  flex-grow: 8;
  color: #fafafa;
  padding-right: 7vw;
  font-size: 30px; */
  color: #fafafa;
  font-size: 30px;
  text-align: center;
  position: absolute;
  width: 92%;
  bottom: 0px;
  font-family: "Roboto";
}
.SpacexNavbar ul {
  padding-left: 0px;
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
}
.SpacexNavbar ul > li {
  margin: 0 12px;
  list-style: none;
  font-family: Roboto, Myanmar3;
  font-size: 16px;
  font-weight: 500;
}
.SpacexNavbar ul > li:last-child {
  letter-spacing: normal;
}

/* .SpacexNavbar li > a {
  transition: color 250ms ease-out;
} */
.SpacexNavbar li > a:hover {
  color: #2cafe8;
}

@media (min-width: 320px) {
  .SpacexNavbar h1 {
    display: none;
  }
  .SpacexNavbar ul {
    justify-content: center;
  }
  .SpacexNavbar ul > li {
    margin: 0 7px;
  }
}
@media (min-width: 920px) {
  .SpacexNavbar h1 {
    display: block;
  }
  .SpacexNavbar ul {
    justify-content: flex-end;
  }
  .SpacexNavbar ul > li {
    margin: 0 12px;
  }
}

.SpacexNavbar + .underline {
  width: 100%;
  padding-bottom: 15px;
  background-color: #273142;
  z-index: 3;
}
.SpacexNavbar + .underline > .underline-inner {
  width: calc(100% - 8vw);
  height: 2px;
  margin: 0 auto;
  background-color: #fafafa;
}
.nav-logout span {
  white-space: nowrap !important;
  color: #fff;
  font-weight: 500 !important;
  cursor: pointer !important;
}
li span:hover {
  color: #2cafe8 !important;
}
@media (max-width: 780px) {
  .SpacexNavbar {
    display: block;
  }
  .SpacexNavbar h1 {
    font-size: 24px;
    display: block;
    position: relative;
    margin: 0px auto 0px auto !important;
  }

  .SpacexNavbar ul.ul-top {
    justify-content: initial;
  }

  .SpacexNavbar ul > li {
    list-style: none;
    font-family: Roboto;
    font-size: 18px;
  }

  .SpacexNavbar ul > li.li-home {
    margin-right: auto;
  }

  .SpacexNavbar ul > li.li-product {
    padding: 0px 7px;
    margin: 0px 5px;
  }

  .SpacexNavbar ul > li.nav-logout {
    margin-left: auto;
  }
}
