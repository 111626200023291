.SpacexHome {
  background-color: #273142;
  display: flex;
  flex-direction: column;
  flex-grow: 0 !important;
  justify-content: center;
  align-items: center;
  font-family: "Roboto" !important;
}

.SpacexHome .upcoming-title {
  color: #fff;
  font-size: 17px;
  margin: 10px 5vw;
  font-family: "Roboto";
}
.SpacexHome .past-title {
  color: #fff;
  font-size: 17px;
  margin: 40px 5vw 10px 5vw;
  font-family: "Roboto";
}
.SpacexHome .show-more {
  color: #fff;
  font-size: 17px;
  margin: 5px 5vw;
  cursor: pointer;
  font-family: "Roboto";
  position: relative;
}
.SpacexHome .show-more .MuiSvgIcon-root {
  position: absolute;
  top: -2px;
  color: #fff !important;
}
.SpacexHome .show-more span {
  padding-left: 25px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Roboto";
}

.SpacexHome .btn-container {
  max-width: 45vmax;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto";
}

.SpacexHome .btn-container .fly-info-text {
  color: #abaeaf;
  margin: 10px 5vw;
  padding: 5px;
  box-sizing: border-box;
  line-height: 23px;
  font-size: 13px;
  font-family: "Roboto";
}
.SpacexHome .fly-info-text ul {
  margin: 5px 0px;
  padding-left: 30px;
}
.SpacexHome .fly-info-text ul li {
  line-height: 20px;
  margin-top: 5px;
}
.SpacexHome .btn-container .fly-info-text > a {
  color: #abaeaf;
}
.SpacexHome .btn-container .fly-info-text > a,
a.travel-link {
  text-decoration: underline;
  transition: color 250ms ease-out;
}
.SpacexHome .btn-container .fly-info-text > a:hover,
a.travel-link:hover {
  color: #2cafe8;
}

.SpacexHome .btn-container a.btn-common {
  padding: 0 30px;
  margin: 10px 5vw;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: #10151d 2px 3px 8px 2px;
  transition: all 250ms ease-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
}

.SpacexHome .btn-container a.btn-common:hover {
  box-shadow: transparent 0px 0px 0px 0px;
}

.SpacexHome .btn-container a.btn-common > img {
  height: 75px;
  width: auto;
  margin: 2vmin;
}

.SpacexHome .btn-container a.btn-fly {
  content: " I want to book a flight";
}
.SpacexHome .btn-container a.btn-sleep {
  content: " I want to book a room";
}
.SpacexHome .btn-container a::after {
  color: #273142;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
  min-width: 200px;
  text-align: center;
  font-family: "Roboto";
}
.SpacexHome .btn-container a.btn-fly::after {
  content: " I want to book a flight";
}
.SpacexHome .btn-container a.btn-sleep::after {
  content: " I want to book a room";
}

.SpacexHome .fly-cancel-div {
  display: table;
  padding: 10px;
  margin: 10px 5vw;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: #10151d 2px 3px 8px 2px;
  transition: all 250ms ease-out;
  box-sizing: border-box;
  font-family: "Roboto";
}
.SpacexHome .btn-region {
  display: table-cell;
  vertical-align: middle;
}
.SpacexHome .fly-cancel-div button {
  background-color: #f50057;
  padding: 6px 15px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  font-family: "Roboto";
}
.SpacexHome .fly-cancel-div p {
  float: left;
  width: 100%;
  margin: 5px 0 0 0;
  color: #273142;
  font-size: 18px;
  font-weight: bold;
  font-family: "Roboto";
  line-height: 20px;
}
.SpacexHome .fly-cancel-div .cancel-info {
  color: #7f91a8 !important;
}

.SpacexHome .fly-cancel-div .cancel-info-red {
  color: #f50057 !important;
}

@media (max-width: 550px) {
  .SpacexHome .btn-container .fly-info-text {
    margin-top: 0px;
    max-width: 40vmax;
  }
  .SpacexHome .btn-container a.btn-fly {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .SpacexHome .btn-container a.btn-fly::after {
    content: " Book a flight";
    margin-top: 0px;
  }
  .SpacexHome .btn-container a.btn-fly img {
    margin-bottom: 0px;
  }
  .SpacexHome .btn-container a.btn-sleep::after {
    content: " Book a room";
    margin-top: 0px;
  }
  .SpacexHome .btn-container a.btn-sleep img {
    margin-bottom: 0px;
  }
  .SpacexHome .fly-cancel-div {
    max-width: 48vmax;
  }
  .SpacexHome .fly-cancel-div h4 {
    font-size: 17px;
  }
  .SpacexHome .fly-cancel-div .btn-region {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
  .SpacexHome .fly-cancel-div button {
    position: initial;
    margin-top: 5px;
    font-size: 16px;
  }
  .SpacexHome .fly-cancel-div p {
    font-size: 17px;
    width: 100%;
  }
}
