.bgBalloon {
  //background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/5dkfxgkO2K0svgdfoLzhf8/f436cab547cd5f271384c430a2acd564/Balloons_BG.jpg");
  //background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1qUci7hJnDqP9UvwJGuGXC/75a889c0fc312a14e3fbaf09404385ce/STT2_1.jpg");
  background-color: #273142;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-width: 100%;
  height: 100vh;
  min-height: 100vh;
}
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.BalloonsCenterInfo {
  // font-family: "Poppins", Myanmar3;
  // font-weight: 400;
  // font-size: 40px;
  // color: #fff;
  // font-weight: 500;
  // text-align: center;
  // margin-top: 10vh !important;
  // position: relative;
  visibility: hidden;
}
.divBalloons {
  margin: auto;
  text-align: right;
}
.balloonPayment {
  .cardHolderDiv {
    margin-bottom: 50px;
  }
}
.payCounter {
  background: #eceded;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;

  max-width: 300px;
  p {
    margin: 0;
    color: #898787;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
  }
  span {
    font-weight: bold;
    color: #898787;
  }
}
.passengerTypeIcon {
  float: left;
  margin-top: 28px;
  margin-right: 15px;
}

.citiesABTypeIcon {
  margin-top: 25px !important;
  width: 17.875px;
  height: 24.969px;
}
.ABTpassengerTypeIcon {
  margin-top: 26px !important;
}
.balloonPassenger {
  margin-top: -50px !important;
}
.balloonPassenger .pasengerTypeCard:after {
  content: "";
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: 8px;
}
.loadingCustInfo {
  height: 24px !important;
  width: 24px !important;
}
.show-popup {
  top: -40px !important;
}
.cities-popup:after {
  left: 30% !important;
}
.passengerTypeTooltip,
.citiesPopupTooltip {
  z-index: 10 !important;
}
.passenger-popup {
  width: 280px !important;
  width: 600px;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
  .MuiGrid-item {
    padding: 7px 12px !important;
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .MuiGrid-item:hover {
    color: #00aeef !important;
    cursor: pointer;
  }
  .homeChooseAge {
    width: 93% !important;
  }
  .ageSelect {
    width: 100% !important;
  }
}
.top.passenger-popup:after {
  content: "";
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
}
.bottom.passenger-popup:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  top: -3px;
  left: 15%;
  position: absolute;
}
.detialInfoDateLbl {
  span {
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;
    color: #7f91a8;
    line-height: 35px;
  }
}
.detialInfoDate {
  text-align: right;
  span {
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    color: #231f20;
    line-height: 35px;
  }
}
.balloonDetailDiv {
  padding: 5px 10px 0px 10px !important;
}
.balloonDetailBox {
  border: 1px solid #e6e7e8;
  padding-bottom: 5px;
  border-radius: 5px;
  background-color: #fff;
}
.paymentPage {
  .balloonDetailBox {
    margin-top: 20px;
  }
}
.totalPriceDiv {
  padding: 10px;
  .mobCusFare {
    padding-right: 0px !important;
  }
}
.switchDiv {
  margin-top: 5px;
  .Component-switchBase-208 {
    padding: 1.5px 2px !important;
  }
  .MuiSwitch-track {
    height: 25px !important;
    border: 1px solid #e6e7e8 !important;
  }
  .MuiSwitch-root {
    height: 30px !important;
  }
  .Component-switchBase-208.Component-checked-211 + .Component-track-210 {
    padding: 1px 2px !important ;
  }
}
.sameContactDiv {
  padding: 10px 0px 15px 0px;
}
.checkContact {
  line-height: 25px !important;
  .sameContactInfo {
    font-weight: 500;
    color: #7f91a8;
    line-height: 30px;
  }
}
.contactMobile.balloonDetailBox {
  padding-bottom: 0px !important;
}
.priceBalloonDetail {
  background-color: #e5f6f1;
  border-radius: 4px;
  padding: 10px 15px;
  height: auto;
  bottom: 0px !important;
}
.termsAndConditionsBox {
  display: block;
  position: absolute;
  top: 10%;
  padding: 20px;
  margin: auto;
  width: 60%;
  touch-action: none;
  border-radius: 8px !important;
  outline: none !important;
  left: calc(10% + 80px);
  // height: calc(60% +100px);
  max-height: 500px;
  .subTitle {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    font-size: 14px;
  }
  .titleBox {
    padding-bottom: 10px;
    .MuiSvgIcon-root {
      float: right;
      cursor: pointer;
    }
  }
  .btnContinue {
    float: right;
    margin-top: 10px !important;
  }

  .btnClose {
    background-color: transparent !important;
    color: blue;
    margin-top: 0px !important;
    padding: 0px 10px !important;
    float: right;
  }
  .description {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    font-size: 16px;
    color: #231f20;
    margin-top: 10px;
    line-height: 25px;
  }
  .descriptionBox {
    max-height: 60vh;
    padding-top: 10px;
    font-family: "Roboto", Myanmar3 !important;
    font-size: 14px;
    line-height: 25px;
    ul {
      padding-inline-start: 30px !important;
    }
    p {
      font-family: "Roboto", Myanmar3 !important;
    }
  }
  .balloonTCBox {
    margin-top: 10px;
  }
  .scroll {
    float: left;

    width: 100%;
    overflow-y: scroll;
  }
  .scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }
  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    height: 30px;
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
  .checkCondition {
    .MuiSvgIcon-root {
      color: #00aeef !important;
      width: 20px !important;
      height: 20px !important;
    }
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 14px;
    color: #231f20;
    line-height: 40px;
    padding-left: 0px;
  }
}
.balloon-search {
  margin-top: -50px !important;
  z-index: 2;
  .btnSearch {
    max-width: 120px !important;
    max-height: 74px !important;

    button {
      width: 150px !important;
    }
  }
}
.passengerFormField {
  padding-left: 15px;
}
.SingleDatePicker_picker__portal {
  z-index: 3;
}
.balloonSearchDiv {
  // border-right: 1px solid #e4e4e4;
  margin-right: 20px !important;
  width: calc(33.3333% - 61.99996px);
  min-width: calc(33.3333% - 61.99996px) !important;
  .DateInput_1 #start_date_id {
    padding-top: 3px !important;
  }
  .departFlight.block {
    line-height: 20px !important;
  }
}
.balloonSearchDiv.departfrom {
  margin-top: 0px !important;
  //line-height: 50px !important;
}
.BalloonPassengerType #PassengerType {
  height: 1.5875em !important;
}
.nomargin {
  margin-top: 0px !important;
}
.balloonConfirmation .confirmation-lower .durationinfo,
.balloonConfirmation .durationinfo .place,
.balloonConfirmation .confirmation-lower .detailheader-region .duration {
  font-weight: 500 !important;
  color: #8f8f8f !important;
}
.balloonConfirmation .confirmation-lower .bookingheader {
  font-weight: 500 !important;
}
.balloonConfirmation
  .confirmation-lower
  .detaildiv
  .detailtext-region
  .fromtoinfo {
  width: 30% !important;
}
.balloonConfirmation .confirmation-lower .detaildiv .detailtext-region img {
  margin: 0px 25px 0px 6px !important;
}
.balloonTitle {
  color: #919191;
  font-size: 20px;
  font-weight: 500;
}
.balloonConfirmDestination {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 20px;
  color: #000;
  line-height: 30px;
}
.BalloonsCenterInfo {
  // margin-top: 15vh !important;
  // text-align: right;
  // .myanmar {
  //   line-height: 120px !important;
  // }
  visibility: hidden;
}

#searchBalloon div.departfrom {
  margin-top: 0px !important;
  //line-height: 73px !important;
  height: 73px !important;
  td {
    line-height: 36px !important;
  }

  #searchBalloon .DateRangePicker_picker {
    bottom: 65px !important;
  }
}
// #searchBalloon .CalendarMonth_caption {
//   padding-top: 0px !important;
// }
.balloonSearchDiv .airportList {
  line-height: 25px;
  height: 35px;
}
.balloonSearchDiv .topCity {
  line-height: 30px;
  height: 50px;
}
#bl-home-btn-search span {
  pointer-events: none;
}
@media (max-width: 780px) {
  .divAirlinePartner .bgWhite.bgBalloonOperators {
    padding: 0px 75px !important;
  }
  #bgBalloon .logo {
    width: 150px !important;
    padding-top: 9px !important;
  }
  .balloon-search {
    height: 300px !important;
    padding: 0px !important;
    margin-top: -20vh !important;
    .passenger {
      padding-top: 0px !important;
    }
  }

  #searchBalloon div.departfrom {
    line-height: 60px !important;
    height: 60px !important;
    label {
      font-size: 16px !important;
    }
    .mobile-from-to {
      margin-top: 5px !important;
    }
  }
  .btnTC {
    min-width: 100% !important;
    min-height: 40px;
  }
  .abt_balloon-search.balloon-search {
    height: 240px !important;
  }
  .balloonSearchDiv {
    border-right: none;
    margin-right: 0px !important;
    min-width: 100% !important;
    height: 45px !important;
    margin-top: 5px !important;
  }
  .BalloonsCenterInfo {
    // font-family: "Poppins", Myanmar3 !important;
    // font-weight: 500;
    // font-size: 15px !important;
    // height: 15px !important;
    // margin-top: -14vh !important;
    // .myanmar {
    //   float: right;
    // }
    visibility: hidden;
  }

  .passengerTypeIcon {
    display: none;
  }
  .totalPriceDiv {
    margin-bottom: 10px !important;
  }
  .termsAndConditionsBox {
    left: calc(3% - 5px) !important;
    width: 86% !important;
    margin: auto !important;
    max-height: 80% !important;
    top: 7% !important;
  }
  .contactMobile.balloonDetailBox {
    padding-bottom: 60px !important;
  }
  .descriptionBox {
    max-height: 350px !important;
    .scroll {
      max-height: 300px !important;
    }
  }
  .continueDiv {
    text-align: center;
    .btnContinue {
      float: none !important;
    }
  }
  .balloonPassengerPopup {
    top: 45% !important;
    max-width: 120px !important;
    max-height: 74px !important;
  }
}
.balloon-search .clickAwayDiv {
  width: 87% !important;
}
.homeContainer .balloon-search .DateInput_1 #start_date_id {
  padding: 10px 2px 7px 7px !important;
  min-width: 130px !important;
}
.homeContainer .balloon-search .DateInput_1 #return_start_date_id {
  padding: 10px 2px 7px 7px !important;
}
.flightSearch .DayPicker_focusRegion {
  margin-top: 50px !important;
}

@media (min-width: 780px) {
  .passengerMobile {
    display: none;
  }
  .mobile-from-to {
    margin-top: 5px !important;
  }
  .balloon-search .departfrom {
    // max-width: calc(33.34% - 40px) !important;
    // min-width: calc(33.34% - 40px) !important;
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(33.36% - 40px) !important;
    max-height: 73px !important;
  }
  .balloon-search.hasReturn .departfrom {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }
  // .balloon-search.hasReturn .btnSearch {
  //   margin-top: -0.8px !important;
  // }
}
@media (max-width: 370px) {
  .balloon-search {
    .mdatepicker .SingleDatePickerInput_calendarIcon {
      width: 48% !important;
    }
    .mdatepicker .SingleDatePickerInput_calendarIcon .fieldLbl {
      width: 113px !important;
    }
    .mdatepicker .DateInput_input {
      font-size: 17px !important;
      width: 135px !important;
    }
    .mdatepicker .DateInput {
      width: 52% !important;
      text-align: left !important;
    }
    .fieldLbl,
    .passenger label,
    .searchBox label {
      font-size: 15px !important;
    }
    .mobile-from-to .MuiFormControl-root {
      width: 77% !important;
    }
  }
}

.div-balloon-partner {
  width: 120px;
}
.div-balloon-eagle-partner {
  width: 190px;
  margin: auto 0px;
}
.div-balloon-bob-partner {
  width: 130px;
  margin: -3px 0px;
}

.divAirlinePartner .bgWhite.bgBalloonOperators {
  padding: 0 220px;
  margin-top: 30px;
}
.flightSearch .balloonSearchDiv.departfrom {
  width: calc(33.3333% - 59.99996px) !important;
  min-width: calc(33.3333% - 59.99996px) !important;
}
.borderRight {
  border-right: 1px solid #ddd;
}
