.flightSearch.spacex-sleephome .balloonSearchDiv.departfrom {
  width: calc(44.3333% - 44.99996px) !important;
  min-width: calc(44.3333% - 44.99996px) !important;
}
.flightSearch.spacex-sleephome .DayPicker_focusRegion {
  margin-top: 0px !important;
}
.flightSearch .btnSearch.spacex-sleep-searchBtn {
  margin-top: -0.1px !important;
}
.spacex-sleep-errMsg {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}
.spacex-sleep-wait {
  margin: 0 auto !important;
  margin-top: 30px !important;
}
.spacex-sleep-wait h1 {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 30px;
  color: #01b7f2;
  font-family: "Roboto";
}
.spacex-sleep-resultBox {
  margin: 0 auto !important;
  margin-top: 30px !important;
}
.spacex-sleep-resultItem {
  display: inline-table;
}
.spacex-sleep-innerItem {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px !important;
  margin: 10px 20px;
}
.spacex-sleep-innerItem.checked {
  background-color: #ddf7ff;
}
.spacex-sleep-resultItem img {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
}
.spacex-sleep-resultItem span {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 15px;
}
.spacex-sleep-bgHome {
  background-color: #273142;
  position: fixed;
  margin: 0;
  top: 0;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  overflow: auto;
}
.spacex-sleep-bgHome .homeContainer {
  padding-bottom: 20px !important;
  height: auto !important;
}
.spacex-sleep-firstCol {
  display: table-cell;
  width: 80% !important;
}
.spacex-sleep-secondCol {
  display: table-cell;
  vertical-align: middle;
  width: 20% !important;
  padding-left: 20px !important;
}
.housing_preference {
  width: 100% !important;
  max-width: 100% !important;
}
.housing_mobile {
  width: 100%;
  display: inline-block;
  margin-top: -15px;
  label {
    color: black !important;
    font-family: "Roboto", Myanmar3 !important;
    font-size: 14px !important;
    display: inline-block;
    width: 100%;
  }
}
.notes_mobile {
  margin-top: 30px !important;
}
//Start Round Checkbox
.roundCheckbox {
  position: relative;
}
.roundCheckbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.roundCheckbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 15px;
}
.roundCheckbox input[type="checkbox"] {
  visibility: hidden;
}
.roundCheckbox input[type="checkbox"]:checked + label {
  background-color: #01b7f2;
  border-color: #01b7f2;
}
.roundCheckbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
//End Round Checkbox
.spacex-sleep-ctnBtn {
  font-family: "Roboto", Myanmar3 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  border-radius: 5px !important;
  background-color: #01b7f2 !important;
  padding: 10px 30px !important;
  margin: 0 auto !important;
}
.hrLine {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
}
.btnContinue .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  margin-left: 5px;
}

.sleep-dropdown {
  margin: 0 auto !important;

  .familySelect {
    width: 40%;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 7px;
    .MuiInputBase-input {
      width: 90%;
      border-radius: 7px;
      border: 0px;
      font-size: 15px;
      padding: 10px !important;
    }
  }
}

.sleep-customerinfo {
  .familySelect.readonly {
    opacity: 0.8;
  }
}

.familySelectMobile {
  opacity: 0.8;
}

//Sleep Mobile
@media (max-width: 780px) {
  #BackgroundContainer .search.spacex-sleephome {
    margin-top: 20px !important;
    height: auto !important;
  }
  #BackgroundContainer .search.spacex-sleephome .departfrom {
    margin-top: 10px !important;
  }
  .spacex-sleep-bgHome .btnBlueSearch {
    margin-top: 30px !important;
  }
  .spacex-sleep-bgHome .btnBlueSearch button {
    height: auto !important;
  }
  .spacex-sleep-innerItem {
    padding: 10px !important;
    margin: 10px 0 10px 0 !important;
  }
  .spacex-sleep-wait h1 {
    font-size: 20px !important;
  }
  .sleep-dropdown {
    margin: 0 auto !important;
    .familySelect {
      width: 100%;
    }
  }
}
