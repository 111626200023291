.date-region {
  .date-inner {
    width: 889px !important;
    margin: auto;

    .SingleDatePickerInput__withBorder {
      border: 0px;
      border-radius: 5px;

      border: 1px solid #dbdbdb !important;
    }
    .DateInput {
      width: 170px;
      padding: 5px 10px;
      border-radius: 5px;
    }
    .DateInput_input {
      padding: 0px;
      color: #000;
      font-size: 14px !important;
      border-bottom: 0px;
    }
    .SingleDatePickerInput_calendarIcon {
      margin: 0px;
      background-color: #ebebeb;
      padding: 10px 15px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      svg {
        margin-top: -5px;
        fill: #000;
      }
    }
    .DateInput_input__focused {
      border-bottom: 0px;
    }

    .SingleDatePicker_picker {
      top: 50px !important;
    }
    .DateInput_fang {
      top: 38px !important;
    }

    .MuiInputBase-formControl {
      border: 1px solid #ebebeb;
      padding: 10px;
      background-color: #fff;
      border-radius: 5px;
      input {
        color: #000;
      }
    }
    .MuiCard-root {
      position: absolute;
      z-index: 999;
      padding: 10px;
      border-radius: 5px !important;
      width: 201px;
      min-height: 80px;
      span {
        width: 100%;
        font-family: "Roboto", Myanmar3 !important;
        display: inline-block;
        cursor: pointer;
        margin-bottom: 10px;
      }
      span:hover {
        color: #00aeef;
      }
    }
  }
}
.selectBox.carItemSelect {
  max-width: 100% !important;
}
.car-list {
  width: 100%;
  border-collapse: collapse;
  td {
    min-height: 40px;
    height: 40px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
.date-region .date-inner.car-date {
  max-width: 1000px !important;
  width: 1000px !important;
}
.car-personal-info .date-inner {
  .SingleDatePicker {
    padding-left: 0px !important;
    .DateInput__disabled {
      background-color: #f2f2f2 !important;
    }
  }
}
.car-personal-info .MuiInputBase-input.Mui-disabled {
  background-color: #f2f2f2 !important;
}
.col-car-reserve {
  width: 40%;
  .btnChoose {
    margin-top: 0px !important;
  }
}
.car-personal-info .contactInfo .MuiGrid-item {
  padding-bottom: 15px;
}
// .car-personal-info .contactInfo {
//   & > .MuiGrid-item:nth-child(3),
//   & > .MuiGrid-item:nth-child(4),
//   & > .MuiGrid-item:nth-child(6),
//   & > .MuiGrid-item:nth-child(9),
//   & > .MuiGrid-item:nth-child(11) {
//     padding-left: 0px !important;
//   }
// }
.car-personal-info .contactInfo {
  & > .MuiGrid-item:nth-child(even) {
    padding-left: 15px;
  }
}
.rc-time-picker-input {
  height: 33px;
}
.rc-time-picker {
  width: 77px !important;
  height: 30px;
}
.car-personal-info
  .date-region
  .date-inner
  .SingleDatePickerInput_calendarIcon {
  padding: 7px 15px !important;
}
.car-personal-info {
  .date-region .date-inner .SingleDatePickerInput__withBorder,
  .date-region .date-inner .SingleDatePickerInput_calendarIcon {
    height: 35px;
  }
  .MuiSvgIcon-root {
    color: #00aeef !important;
  }
  .btnContinue {
    width: 147px !important;
  }
}
.car-terms {
  text-align: left;
  line-height: 25px;
  font-size: 14px;
}
.mobile-rc-picker {
  width: 100px;
  .rc-time-picker-input {
    height: 40px !important;
    margin-top: 12px;
  }
  .rc-time-picker-clear {
    height: 40px;
    top: 20px;
  }
}
.car-reason {
  // margin-top: -85px !important;
}
.termsBox {
  max-height: 74vh;
  overflow: scroll !important;
  width: 50vw;
  top: 10% !important;
  left: 25vw !important;
}
@media (max-width: 760px) {
  .termsBox {
    left: 0px !important;
  }
}
