.SpacexWrapper {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background-color: #273142;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.SpacexWrapper > *:nth-child(3) {
  flex-grow: 1;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .SpacexWrapper {
    min-height: 100vh;
    /* min-height: -webkit-fill-available; */
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .SpacexWrapper {
    min-height: 100vh;
  }
}
